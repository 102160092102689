import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const UpdateService = () => {
    const [token, setToken] = useState('')
    const [serviceID, setServiceID] = useState(0)
    const [service, setService] = useState({ name: "" })
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        name: "",
        description: "p",
        category: "",
        slots: 0
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateItem = () => {
        console.log(values)
        //authenticate here
        if ((values.description == "") || (values.name == "") || (values.category == "") || (values.slots == 0)) {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .patch(Global.url + "/subscription/service/" + serviceID, { "description": values.description, "category": values.category, "name": values.name, "slots": values.slots }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Service",
                        "Updated successfully.",
                        "success").then(() => {
                            navigate("/view-service", { state: { "serviceID": serviceID } })
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getService = async (serviceID) => {
        axios
            .get(Global.url + "/subscription/service/" + serviceID)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setService(message)
                    document.getElementById("name").value = message.name
                    document.getElementById("description").value = message.description
                    document.getElementById("slots").value = message.slots
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)

        if (state != null) {
            if ("serviceID" in state) {
                setServiceID(state.serviceID)
                getService(state.serviceID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update service</Text>

            </Box>

            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="name" id="name" onChange={onChange} type="text" placeholder="Name" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Select name="category" onChange={onChange} placeholder="Product category" >
                                <option value="music">Music</option>
                                <option value="movies">Movies & Series</option>
                                <option value="livesports">Live Sports</option>
                                <option value="anime">Anime</option>
                                <option value="vpn">VPN</option>
                                <option value="entertainment">Entertainment</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Input name="description" id="description" onChange={onChange} type="text" placeholder="Description" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Slots</FormLabel>
                            <Input name="slots" id="slots" onChange={onChange} type="number" placeholder="Slots" />
                        </FormControl>
                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateItem}>
                                Update
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}