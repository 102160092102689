import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const UpdateProduct = () => {
    const [token, setToken] = useState('')
    const [itemID, setItemID] = useState(0)
    const [product, setProduct] = useState({ name: "" })
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        name: "",
        category: "",
        summary: "",
        itemType: ""
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateItem = () => {
        console.log(values)
        //authenticate here
        if ((values.summary == "") || (values.name == "") || (values.itemType == "") || (values.category == "")) {
            Swal.fire("Product",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .patch(Global.url + "/product/item/" + itemID, { "summary": values.summary, "name": values.name, "itemType": values.itemType, "category": values.category }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    Swal.fire("Product",
                        "Updated successfully.",
                        "success").then(() => {
                            navigate("/view-product", { state: { "itemID": itemID } })
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getProduct = async (id) => {
        axios
            .get(Global.url + "/product/item/" + id)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setProduct(message)
                    document.getElementById("name").value = message.name
                    document.getElementById("summary").value = message.summary
                    document.getElementById("itemType").value = message.itemType
                    document.getElementById("category").value = message.category
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)

        if (state != null) {
            if ("itemID" in state) {
                setItemID(state.itemID)
                getProduct(state.itemID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update product</Text>

            </Box>

            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="name" id="name" onChange={onChange} type="text" placeholder="Name" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Summary</FormLabel>
                            <Input name="summary" id="summary" onChange={onChange} type="text" placeholder="Summary" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Select name="category" onChange={onChange} placeholder="Product category" >
                                <option value="sale">Sale</option>
                                <option value="upcoming">Upcoming</option>
                                <option value="deal">Deal</option>
                                <option value="recent">Recent Releases</option>
                                <option value="trending">Trending</option>
                                <option value="exclusive">Nitrade Exclusive</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Item Type</FormLabel>
                            <Input name="itemType" id="itemType" onChange={onChange} type="text" placeholder="Item type" />
                        </FormControl>
                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateItem}>
                                Update
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}