import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"
import { ViewService } from "./view-service"

export const ManageService = () => {
    const [token, setToken] = useState('')
    const [services, setServices] = useState([])
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        name: "",
        description: "",
        category:"",
        slots: 0
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const AddService = () => {
        console.log(values)
        //authenticate here
        if ((values.slots == 0) || (values.name == "")|| (values.category == "") || (values.description == "")) {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/subscription/service", { "name": values.name,  "description": values.description,  "category": values.category, "slots": values.slots }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Service",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getServices = async () => {
        axios
            .get(Global.url + "/subscription/item")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setServices(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const viewService = (e) => {
        var serviceID = e.target.getAttribute("data-id")
        navigate("/view-service", { state: { "serviceID": serviceID } })
    }


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getServices()
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Manage services</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>List({services.length})</Tab>
                        <Tab>Create Service</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TableContainer>
                                <Table variant='simple'>
                                    <TableCaption>Services</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Slots</Th>
                                            <Th>Slots Available</Th>
                                            <Th>Num Of Users</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {services.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No services</Text></Tr> : ""}
                                        {services.map((item) => (
                                            <Tr>
                                                <Td>{item.name}</Td>
                                                <Td>{item.slots}</Td>
                                                <Td>{item.slotsAvailable}</Td>
                                                <Td>{item.users}</Td>
                                                <Td data-id={item.serviceProfileID}
                                                    onClick={viewService}>View</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Name</Th>
                                        <Th>Slots</Th>
                                        <Th>Slots Available</Th>
                                        <Th>Num Of Users</Th>
                                        <Th>Action</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input name="name" onChange={onChange} type="text" placeholder="Service name" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Category</FormLabel>
                                        <Select name="category" onChange={onChange} placeholder="Product category" >
                                            <option value="music">Music</option>
                                            <option value="movies">Movies & Series</option>
                                            <option value="livesports">Live Sports</option>
                                            <option value="anime">Anime</option>
                                            <option value="vpn">VPN</option>
                                            <option value="entertainment">Entertainment</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Input name="description" onChange={onChange} type="text" placeholder="Description" />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel>Slots</FormLabel>
                                        <Input name="slots" onChange={onChange} type="number" placeholder="Number of slots per account" />
                                    </FormControl>

                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddService}>
                                            Add Service
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}