import { Flex } from "@chakra-ui/react"
import { ManageAccount } from "../components/account/manage-account"
import { Sidebar } from "../components/sidebar/sidebar"

export const AccountsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageAccount/>
        </Flex>
    )
}