import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const ManageUser = () => {
    const [token, setToken] = useState('')
    const [users, setUsers] = useState([])
    const [status, setStatus] = useState("true")
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        username: "",
        email: "",
        phoneNumber: "",
        searchPhoneNumber: ""
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const AddUser = () => {
        console.log(values)
        //authenticate here
        if (values.phoneNumber == "") {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/user", { "username": values.username, "phoneNumber": values.phoneNumber, "email": values.email }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("User",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    const editUser = (e) => {
        var userID = e.target.getAttribute("data-id")
        navigate("/user/update", { state: { "userID": userID} })
    }

    const searchUser = async () => {
        axios
        .get(Global.url + "/user/search/" + values.searchPhoneNumber, { headers: { token: token } })
        .catch(err => {
            // what now?
            console.log(err);
        }).then(res => {
            console.log(res);
            if (res.data.success == 1) {
                var message = res.data.message
                setUsers(message)
            } else {
                Swal.fire("Error", res.data.message, "error");
            }
        });
    }

    const getUsers = async (tokenStr) => {
        axios
            .get(Global.url + "/user/" + status, { headers: { token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setUsers(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getUsers(tokenStr)
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Manage users</Text>

            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>List({users.length})</Tab>
                        <Tab>Create User</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <VStack w="full" align="start">
                                <FormControl>
                                    <HStack padding={8} w="full">
                                        <Input flex="11" name="searchPhoneNumber" type="text" onChange={onChange} placeholder="Search by username or phone number eg +254705..." />
                                        <Button flex="1" w="200px" py={4} variant="outline" colorScheme="teal.500" onClick={searchUser}>
                                            Search
                                        </Button>
                                    </HStack>
                                </FormControl>
                                <TableContainer w="full">
                                    <Table variant='simple' w="full">
                                        <TableCaption>Users</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>UserID</Th>
                                                <Th>Name</Th>
                                                <Th>Phone Number</Th>
                                                <Th>Action#1</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {users.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No users</Text></Tr> : ""}
                                            {users.map((item) => (
                                                <Tr>
                                                    <Td>{item.userId}</Td>
                                                    <Td>{item.alias}</Td>
                                                    <Td>{item.phoneNumber}</Td>
                                                    <Td data-id={item.userId} onClick={editUser}>Update</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>UserID</Th>
                                            <Th>Name</Th>
                                            <Th>Phone Number</Th>
                                            <Th>Action#1</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>IG Username</FormLabel>
                                        <Input name="username" onChange={onChange} type="text" placeholder="IG Username" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input name="email" onChange={onChange} type="email" placeholder="User email" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Phone Number</FormLabel>
                                        <Input name="phoneNumber" onChange={onChange} type="text" placeholder="Phone Number" />
                                    </FormControl>

                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddUser}>
                                            Add User
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}