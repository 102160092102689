import { Flex } from "@chakra-ui/react"
import { UpdateBlog } from "../components/articles/update-blog"
import { Sidebar } from "../components/sidebar/sidebar"

export const UpdateArticlePage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateBlog/>
        </Flex>
    )
}
