import { Flex } from "@chakra-ui/react"
import { ViewProduct } from "../components/product/view-product"
import { Sidebar } from "../components/sidebar/sidebar"

export const ViewProductPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ViewProduct/>
        </Flex>
    )
}