import { Flex } from "@chakra-ui/react"
import { Sidebar } from "../components/sidebar/sidebar"
import { ManageSubscription } from "../components/subscription/manage-subscription"

export const SubscriptionsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageSubscription/>
        </Flex>
    )
}