import { Flex } from "@chakra-ui/react"
import { ManageGames } from "../components/event/manage-games"
import { Sidebar } from "../components/sidebar/sidebar"

export const GamesPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageGames/>
        </Flex>
    )
}
