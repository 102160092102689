import { Box, Button, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import { useState } from "react"
import { Dashboard } from "../components/dashboard/dashboard"
import { Sidebar } from "../components/sidebar/sidebar"



export const DashboardPage = () => {
    const [userType, setUserType] = useState("")
    const [userID, setUserID] = useState("")
    useEffect(() => {
        setUserID(localStorage.getItem("userID"))
        setUserType(localStorage.getItem("userType"))
        console.log(userType)
    }, [])
    return (
        <Flex>
            <Sidebar />
            <Dashboard userType ={userType}/>
        </Flex>
    )
}