import { Flex } from "@chakra-ui/react"
import { UpdateVariant } from "../components/product/update-variant"
import { Sidebar } from "../components/sidebar/sidebar"

export const UpdateVariantPage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateVariant/>
        </Flex>
    )
}