import {
    Box,
    Text,
    HStack,
    Flex,
    VStack,
} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { Global } from '../../utils/global';

export const Operations = (props) => {
    const getArticlesCount = async () => {
        axios
            .get(Global.url + "/article/count")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    setBlogsCount(message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getTransactions = async (t) => {
        axios
            .get(Global.url + "/payment/mpesa/transaction", { headers: { token: t } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    setTotal(message.total)
                    setTransactions(message.transactions)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const [blogsCount, setBlogsCount] = useState(0)
    const [total, setTotal] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [userType, setUserType] = useState("")
    const [token, setToken] = useState("")
    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getArticlesCount()
        if (userType == "2") {
            getTransactions(tokenStr)
        }
    }, [])
    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}
        >
            <Box w="full" py={4} >
                <HStack flexDir="row" w="full" >
                    <Link to="/transactions">
                        <Box p={4} flex="1" borderWidth={1} borderColor="gray.100">
                            <Flex w="full" flexDir="row">
                                <Box flex="3">
                                    <VStack w="full" align="flex-start">
                                        <Flex w="full" flexDir="row" align="flex-start">
                                            <Text fontSize={20} fontWeight="semibold">Transactions</Text>
                                            <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                        </Flex>
                                        {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                        <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">MPesa Payment</Text>
                                    </VStack>
                                </Box>
                                <Box flex="1">
                                    <Flex w="full" h="full" flexDir="column" align="flex-end">
                                        <HStack>
                                            <Text fontSize={36} fontWeight="semibold">{numberWithCommas(transactions.length)}</Text>
                                            <Text fontSize={16} fontWeight="semibold"> Transactions Count</Text>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                    <Link to="/articles">
                        <Box p={4} flex="1" borderWidth={1} borderColor="gray.100">
                            <Flex w="full" flexDir="row">
                                <Box flex="3">
                                    <VStack w="full" align="flex-start">
                                        <Flex w="full" flexDir="row" align="flex-start">
                                            <Text fontSize={20} fontWeight="semibold">Blogs</Text>
                                            <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                        </Flex>
                                        {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                        <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Content in blog section</Text>
                                    </VStack>
                                </Box>
                                <Box flex="1">
                                    <Flex w="full" h="full" flexDir="column" align="flex-end">
                                        <HStack>
                                            <Text fontSize={36} fontWeight="semibold">{numberWithCommas(blogsCount)}</Text>
                                            <Text fontSize={16} fontWeight="semibold">All articles</Text>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                    {userType == 2 ? <>
                        <Link to="/sales">
                            <Box p={4} flex="1" borderWidth={1} borderColor="gray.100">
                                <Flex w="full" flexDir="row">
                                    <Box flex="3">
                                        <VStack w="full" align="flex-start">
                                            <Flex w="full" flexDir="row" align="flex-start">
                                                <Text fontSize={20} fontWeight="semibold">Revenue</Text>
                                                <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                            </Flex>
                                            {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                            <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Monthly, quartely and yearly sales</Text>
                                        </VStack>
                                    </Box>
                                    <Box flex="1">
                                        <Flex w="full" h="full" flexDir="column" align="flex-end">
                                            <HStack>
                                                <Text fontSize={36} fontWeight="semibold">{numberWithCommas(total)}</Text>
                                                <Text fontSize={16} fontWeight="semibold"> Total Revenue</Text>
                                            </HStack>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                        </Link>
                    </> : ""}
                </HStack>
                <HStack flexDir="row" w="full" >
                    <Link to="/games">
                        <Box p={4} flex="1" borderWidth={1} borderColor="gray.100">
                            <Flex w="full" flexDir="row">
                                <Box flex="3">
                                    <VStack w="full" align="flex-start">
                                        <Flex w="full" flexDir="row" align="flex-start">
                                            <Text fontSize={20} fontWeight="semibold">Games</Text>
                                            <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                        </Flex>
                                        {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                        <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Games in events home screen</Text>
                                    </VStack>
                                </Box>
                                <Box flex="1">
                                    <Flex w="full" h="full" flexDir="column" align="flex-end">
                                        <HStack>
                                            <Text fontSize={36} fontWeight="semibold">{numberWithCommas(blogsCount)}</Text>
                                            <Text fontSize={16} fontWeight="semibold">All games</Text>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                    <Link to="/event">
                        <Box p={4} flex="1" borderWidth={1} borderColor="gray.100">
                            <Flex w="full" flexDir="row">
                                <Box flex="3">
                                    <VStack w="full" align="flex-start">
                                        <Flex w="full" flexDir="row" align="flex-start">
                                            <Text fontSize={20} fontWeight="semibold">Tournaments</Text>
                                            <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                        </Flex>
                                        {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                        <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">View and manage tournaments</Text>
                                    </VStack>
                                </Box>
                                <Box flex="1">
                                    <Flex w="full" h="full" flexDir="column" align="flex-end">
                                        <HStack>
                                            <Text fontSize={36} fontWeight="semibold">{numberWithCommas(blogsCount)}</Text>
                                            <Text fontSize={16} fontWeight="semibold">All tournaments</Text>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                    <Link to="/platform">
                        <Box p={4} flex="1"  borderWidth={1} borderColor="gray.100">
                            <Flex w="full" flexDir="row">
                                <Box flex="3">
                                    <VStack w="full" align="flex-start">
                                        <Flex w="full" flexDir="row" align="flex-start">
                                            <Text fontSize={20} fontWeight="semibold">Platforms</Text>
                                            <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                        </Flex>
                                        {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                        <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">View and manage platforms</Text>
                                    </VStack>
                                </Box>
                                <Box flex="1">
                                    <Flex w="full" h="full" flexDir="column" align="flex-end">
                                        <HStack>
                                            <Text fontSize={36} fontWeight="semibold">{numberWithCommas(blogsCount)}</Text>
                                            <Text fontSize={16} fontWeight="semibold">All platforms</Text>
                                        </HStack>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </Link>
                </HStack>
            </Box>
        </Box>
    )
}