import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    HStack,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
    Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Navbar } from '../components/navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Global } from '../utils/global';
import mixpanel from 'mixpanel-browser';
mixpanel.init(Global.mixpanelToken,{
    debug:true
})

export const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        password: "",
        phoneNumber: "",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const registerPatient = () => {
        console.log(values)
        //authenticate here
        axios
            .post(Global.url + "/patient", { firstName: values.firstName,lastName: values.lastName, email: values.email, password: values.password, sex: values.gender, phoneNumber: values.phoneNumber })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Register",
                        "Registration successful.",
                        "success").then(() => {
                            mixpanel.track("Sign Up",{"userType":"patient"})
                            navigate("/account/confirm")
                        })
                } else {
                    Swal.fire("Registration error", res.data.message, "error");
                }
            });
    }
    return (
        <Box px={{ lg: '32', sm: "2" }}>
            {/* Navbar */}
            <Navbar />
            {/* Navbar End */}

            {/* Main Content Start */}
            <Box p={4} w="full">
                <Flex
                    minH={'90vh'}
                    align={'center'}
                    justify={'center'}
                    bg={useColorModeValue('gray.50', 'gray.800')}>
                    <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                        <Stack align={'center'}>
                            <Heading fontSize={'4xl'} textAlign={'center'}>
                                Sign up
                            </Heading>
                            <Text fontSize={'lg'} color={'gray.600'}>
                                Are you a patient? Register to unlock more features
                            </Text>
                        </Stack>
                        <Box
                            rounded={'lg'}
                            bg={useColorModeValue('white', 'gray.700')}
                            boxShadow={'lg'}
                            p={8}>
                            <Stack spacing={4}>
                                <HStack>
                                    <Box>
                                        <FormControl id="firstName" isRequired>
                                            <FormLabel>First Name</FormLabel>
                                            <Input type="text" name="firstName" onChange={onChange} />
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl id="lastName">
                                            <FormLabel>Last Name</FormLabel>
                                            <Input type="text" name="lastName" onChange={onChange} />
                                        </FormControl>
                                    </Box>
                                </HStack>
                        
                                <FormControl id="email" isRequired>
                                    <FormLabel>Email address</FormLabel>
                                    <Input name="email" type="email"  onChange={onChange}/>
                                </FormControl>
                                <FormControl id="phonenUmber" isRequired>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Input name="phoneNumber" type="text" onChange={onChange}/>
                                </FormControl>
                                <FormControl id="gender" isRequired>
                                    <FormLabel>Gender</FormLabel>
                                    <Select name="gender" placeholder='Select gender'  onChange={onChange}>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                    </Select>
                                </FormControl>
                                <FormControl id="password" isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <Input name="password" type={showPassword ? 'text' : 'password'}  onChange={onChange}/>
                                        <InputRightElement h={'full'}>
                                            <Button
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Stack spacing={10} pt={2}>
                                    <Button
                                        variant={"outline"}
                                        colorScheme="teal"
                                        onClick={registerPatient}>
                                        Sign up
                                    </Button>
                                </Stack>
                                <Stack pt={6}>
                                    <Text align={'center'}>
                                        Already a user? <Link href="/login" color={'teal.400'}>Login</Link>
                                    </Text>
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Flex>
            </Box>
        </Box>
    );
}