import { Flex } from "@chakra-ui/react"
import { UpdateService } from "../components/service/update-service"
import { Sidebar } from "../components/sidebar/sidebar"

export const UpdateServicePage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateService/>
        </Flex>
    )
}