import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"
import { useRef } from "react"

export const ViewProduct = () => {
    const [img, setImg] = useState("images/eject.png");
    const [selectedFile, setSelectedFile] = useState(null);
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    const inputFile = useRef(null)
    const openFile = () => {
        inputFile.current.click();
    }
    const [token, setToken] = useState('')
    const [itemID, setItemID] = useState(0)
    const [product, setProduct] = useState({})
    const [variants, setVariants] = useState([])
    const [usertype, setUserType] = useState("")

    const { state } = useLocation();

    const [imgVariant, setImgVariant] = useState("images/eject.png");
    const [selectedFileVariant, setSelectedFileVariant] = useState(null);
    const onImageChangeVariant = (e) => {
        const [file] = e.target.files;
        setSelectedFileVariant(e.target.files[0])
        setImgVariant(URL.createObjectURL(file));
    };
    const inputFileVariant = useRef(null)
    const openFileVariant = () => {
        inputFileVariant.current.click();
    }

    const [values, setValues] = useState({
        name: "",
        platform: "",
        salePrice: 0,
        purchasePrice: 0,
        region: "",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const deleteProduct = () => {

    }
    const updateProduct = () => {
        navigate("/product/update", { state: { "itemID": itemID } })
    }
    const AddVariant = () => {

        //authenticate here

        if (selectedFileVariant == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        axios
            .post(Global.url + "/product/item/variant", { "platform": values.platform, "name": values.name, "itemID": product.itemID, "region": values.region, "purchasePrice": values.purchasePrice + "", "salePrice": values.salePrice }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");
                    addPhotoVariant(res.data.message.variantID)

                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getVariants = async (itemID) => {
        axios
            .get(Global.url + "/product/item/" + itemID + "/variant", { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    // console.log("variants:",message)
                    setVariants(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getProduct = async (itemID) => {
        axios
            .get(Global.url + "/product/item/" + itemID)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setProduct(message)
                    if (message.image != "") {
                        setImg(Global.awsS3url + "/images/" + message.imageUrl)
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    function updateImage() {
        addPhoto(itemID)
    }
    function addPhoto(itemID) {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "product-" + itemID);

        // var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Photo Update", res.data.message, "success").then(() => {
                        navigate("/view-product", { state: { "itemID": itemID } })
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    function addPhotoVariant(itemID) {
        if (selectedFileVariant == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFileVariant);
        formData.append("itemID", "variant-" + itemID);

        // var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Photo Update", res.data.message, "success").then(() => {
                        navigate("/view-product", { state: { "itemID": product.itemID } })
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const updateVariant = (e) => {
        var itemID = e.target.getAttribute("data-id")
        navigate("/update-variant", { state: { "itemID": itemID } })
    }

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        if (state != null) {
            if ("itemID" in state) {
                console.log(state.itemID)
                getProduct(state.itemID)
                getVariants(state.itemID)
                setItemID(state.itemID)
            }
        }

    }, [state])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >View product</Text>

            </Box>

            <Box p={8} w="full">
                <VStack align="start" w="full">
                    <HStack w="full" align="start" spacing={8}>
                        <Flex flex={1} flexDir="column" borderRightColor="gray.100" borderRightWidth={1}>
                            <VStack spacing={6} align="start">
                                <p>Name: <span>{product.name}</span></p>
                                <p>Category: <span>{product.category}</span></p>
                                <p>Summary: <span>{product.summary}</span></p>
                                <p>Type: <span>{product.itemType}</span></p>
                            </VStack>
                            <Box mt={4} w="full" alignItems="center">
                                <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={updateProduct}>
                                    Update
                                </Button>

                                <Button w="200px" variant="outline" mr={4} color="gray" onClick={deleteProduct}>
                                    Delete
                                </Button>
                            </Box>
                        </Flex>
                        <VStack flex={1} align="start" spacing={4}>
                            <Heading fontWeight="normal" fontSize="24px">More Details</Heading>
                            <Text>Sales: 0</Text>
                            <Img src={img} onClick={openFile} alt="Product Image" w="100px" h="100px" />
                            <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                            <Button w="200px" variant="outline" onClick={updateImage} mr={4} color="gray" >
                                Update Image
                            </Button>
                        </VStack>
                    </HStack>


                    <Tabs pt={8} w="full">
                        <TabList>
                            <Tab>Variants</Tab>
                            <Tab>Create Variant</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <TableCaption>Product Variants</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Name</Th>
                                                <Th>Platform</Th>
                                                <Th>Region</Th>
                                                <Th>Purchase</Th>
                                                <Th>Sale</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {variants.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No Variants</Text></Tr> : ""}
                                            {variants.map((item) => (
                                                <Tr>
                                                    <Td>{item.name}</Td>
                                                    <Td>{item.platform}</Td>
                                                    <Td>{item.region}</Td>
                                                    <Td>{item.purchasePrice}</Td>
                                                    <Td>{item.salePrice}</Td>
                                                    <Td data-id={item.variantID}
                                                        onClick={updateVariant}>Update</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>

                                        <Tfoot>
                                            <Th>Name</Th>
                                            <Th>Platform</Th>
                                            <Th>Region</Th>
                                            <Th>Purchase</Th>
                                            <Th>Sale</Th>
                                            <Th>Action</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        <FormControl>
                                            <FormLabel>Name</FormLabel>
                                            <Input name="name" onChange={onChange} type="text" placeholder="Variant Name" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Platform</FormLabel>
                                            <Select name="platform" onChange={onChange} placeholder="Select platform" >
                                                <option value="playstation">playstation</option>
                                                <option value="xbox">xbox</option>
                                                <option value="nintendo">nintendo</option>
                                                <option value="pc">pc</option>
                                                <option value="amazon">amazon</option>
                                                <option value="google">google</option>
                                                <option value="microsoft">Microsoft</option>
                                                <option value="meta">Meta</option>
                                                <option value="apple">apple</option>
                                                <option value="roku">roku</option>
                                                <option value="redmi">redmi</option>
                                                <option value="samsung">samsung</option>
                                                <option value="jbl">JBL</option>
                                                <option value="huawei">Huawei</option>
                                                <option value="anker">Anker</option>
                                                <option value="mi">Mi</option>
                                                <option value="onyx">Onyx</option>
                                                <option value="windows">windows</option>
                                                <option value="oneplus">oneplus</option>
                                                <option value="starlink">startlink</option>
                                                <option value="dell">dell</option>
                                                <option value="hp">hp</option>
                                                <option value="lenovo">Lenovo</option>
                                                <option value="asus">Asus</option>
                                                <option value="lg">LG</option>
                                                <option value="sony">Sony</option>
                                                <option value="nvidia">Nvidia</option>
                                                <option value="hotec">Hotec</option>
                                                <option value="jabra">Jabra</option>
                                                <option value="toshiba">Toshiba</option>
                                                <option value="bose">Bose</option>
                                                <option value="dell">dell</option>
                                                <option value="celestron">celestron</option>
                                                <option value="night-owl">Night Owl</option>
                                                <option value="apexel">Apexel</option>
                                                <option value="seetec">Seetec</option>
                                                <option value="logitech">Logitech</option>
                                                <option value="elgato">Elgato</option>
                                                <option value="pioneer">Pioneer</option>
                                                <option value="pyle">Pyle</option>
                                                <option value="subrent">Subrent</option>
                                                <option value="coman">Coman</option>
                                                <option value="feelword">Feelworld</option>
                                                <option value="sandisk">Sandisk</option>
                                                <option value="owc">OWC</option>
                                                <option value="G-Technology">G-Technology</option>
                                                <option value="gosky">Gosky</option>
                                                <option value="holy-stone">Holy Stone</option>
                                                <option value="nikon">Nikon</option>
                                                <option value="kodak">Kodak</option>
                                                <option value="netgear">Netgear</option>
                                                <option value="blackmagic">Blackmagic</option>
                                                <option value="blackvue">Blackvue</option>
                                                <option value="mirabox">Mirabox</option>
                                                <option value="cable-matters">Cable Matters</option>
                                                <option value="pluggable">Pluggable</option>
                                                <option value="tamron">Tamron</option>
                                                <option value="benfiss">Benfiss</option>
                                                <option value="my-cloud">MyCloud</option>
                                                <option value="panasonic">Panasonic</option>
                                                <option value="duracell">Duracell</option>
                                                <option value="polarduck">polarduck</option>
                                                <option value="thinkdiag">thinkdiag</option>
                                                <option value="NOCO">NOCO</option>
                                                <option value="amcrest">Amcrest</option>
                                                <option value="blue-drive">Blue Drive</option>
                                                <option value="thinkdriver">Thinkdriver</option>
                                                <option value="arteck">Arteck</option>
                                                <option value="canon">Canon</option>
                                                <option value="uperfect">Uperfect</option>
                                                <option value="j-tech">J-Tech</option>
                                                <option value="plantronics">Plantronics</option>
                                                <option value="wavlink">Wavlink</option>
                                                <option value="Tiffen">Tiffen</option>
                                                <option value="other">Other</option>
                                            </Select>
                                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Purchase Price</FormLabel>
                                            <Input name="purchasePrice" onChange={onChange} type="number" placeholder="Purchase Price" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Sale Price</FormLabel>
                                            <Input name="salePrice" onChange={onChange} type="number" placeholder="Sale Price" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Region</FormLabel>
                                            <Select name="region" onChange={onChange} placeholder="Select region" >
                                                <option value="uae">United Arab Emirates</option>
                                                <option value="usa">USA</option>
                                                <option value="uk">United Kingdom</option>
                                                <option value="sa">South Africa</option>
                                                <option value="global">global</option>
                                            </Select>
                                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                                        </FormControl>
                                        <VStack flex={1} align="start" spacing={4}>
                                            <Heading fontWeight="normal" fontSize="24px">Image</Heading>
                                            <Img src={imgVariant} onClick={openFileVariant} alt="Variant Image" w="100px" h="100px" />
                                            <Input type="file" ref={inputFileVariant} onChange={onImageChangeVariant} visibility="hidden"></Input>
                                        </VStack>

                                        <Box mt={4} w="full" alignItems="center">
                                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddVariant}>
                                                Add Variant
                                            </Button>
                                        </Box>
                                    </VStack>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </VStack>
            </Box>
        </Box>
    )
}