import { Flex } from "@chakra-ui/react"
import { Operations } from "../components/operations/operations"
import { Sidebar } from "../components/sidebar/sidebar"

export const OperationsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <Operations/>
        </Flex>
    )
}
