import { Flex } from "@chakra-ui/react"
import { Sidebar } from "../components/sidebar/sidebar"
import { UpdateUser } from "../components/user/update-user"

export const UpdateUserPage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateUser/>
        </Flex>
    )
}