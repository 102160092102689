import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const ManageAccount = () => {
    const [token, setToken] = useState('')
    const [status, setStatus] = useState('active')
    const [accounts, setAccounts] = useState([])
    const [services, setServices] = useState([])
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        email: "",
        password: "",
        package: "",
        service: "",
        searchEmail: "",
        renewalDate: ""
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const AddAccount = () => {
        console.log(values)
        //authenticate here
        if ((values.email == "") || (values.password == "") || (values.service == "") || (values.renewalDate == "")) {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/account", { "email": values.email, "password": values.password, "service": values.service, "package": values.package, "renewalDate": values.renewalDate }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Account",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const searchAccounts = async () => {
        axios
            .get(Global.url + "/account/search/" + values.searchEmail, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAccounts(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    const getAccounts = async (strToken) => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/account/status/" + status, { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAccounts(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const getServices = async () => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/item")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setServices(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const viewAccount = (e) => {
        var accountID = e.target.getAttribute("data-id")
        navigate("/view-account", { state: { "accountID": accountID } })
    }


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getAccounts(tokenStr)
        getServices()
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Manage accounts</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>Active({accounts.length})</Tab>
                        <Tab>Create</Tab>
                    </TabList>
                    <TabPanels>

                        <TabPanel>
                            <VStack w="full" align="start">
                                <FormControl>
                                    <HStack padding={8} w="full">
                                        <Input flex="11" name="searchEmail" type="email" onChange={onChange} placeholder="Search by email" />
                                        <Button flex="1" w="200px" py={4} variant="outline" colorScheme="teal.500" onClick={searchAccounts}>
                                            Search
                                        </Button>
                                    </HStack>
                                </FormControl>
                                <TableContainer w="full">
                                    <Table variant='simple' w="full">
                                        <TableCaption>Accounts</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Account ID</Th>
                                                <Th>Email</Th>
                                                <Th>Renewal Date</Th>
                                                <Th>Service</Th>
                                                <Th>Package</Th>
                                                <Th>Status</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {accounts.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No accounts</Text></Tr> : ""}
                                            {accounts.map((item) => (
                                                <Tr>
                                                    <Td>{item.accountID}</Td>
                                                    <Td>{item.email}</Td>
                                                    <Td>{item.renewalDate}</Td>
                                                    <Td>{item.service}</Td>
                                                    <Td>{item.package}</Td>
                                                    <Td>{item.status}</Td>
                                                    <Td data-id={item.accountID}
                                                        onClick={viewAccount}>View</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Account ID</Th>
                                            <Th>Email</Th>
                                            <Th>Renewal Date</Th>
                                            <Th>Service ID</Th>
                                            <Th>Package</Th>
                                            <Th>Status</Th>
                                            <Th>Action</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input name="email" onChange={onChange} type="email" placeholder="Account email" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Password</FormLabel>
                                        <Input name="password" onChange={onChange} type="text" placeholder="Password" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Renewal Date</FormLabel>
                                        <Input name="renewalDate" onChange={onChange} type="text" placeholder="Renewal Date eg 25-12-2021" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Service</FormLabel>
                                        <Select name="service" onChange={onChange} placeholder="Service">
                                            {services.map((service) => (
                                                <option value={service.name}>{service.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {values.service == "dstv" ? <FormControl>
                                        <FormLabel>Package</FormLabel>
                                        <Select name="package" onChange={onChange} placeholder="Package eg premium" >
                                            <option value="compact">Compact</option>
                                            <option value="premium">Premium</option>
                                        </Select>
                                    </FormControl> : ""}

                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddAccount}>
                                            Add Account
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}