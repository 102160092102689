import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"
import { useRef } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"


export const UpdateEvent = () => {
    const editorRef = useRef();
    const { eventID } = useLocation()

    const styles = {
        editor: {
            border: '1px solid gray',
            minHeight: '6em'
        }
    };

    const [img, setImg] = useState("assets/images/eject.png");
    const [token, setToken] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [articleID, setArticleID] = useState(0)
    const [userID, setUserID] = useState(0)
    const [event, setEvent] = useState({})
    const [usertype, setUserType] = useState("")
    const inputFile = useRef(null)

    const [values, setValues] = useState({
        name: "",
        description: "",
        prize: "",
        startDate: "",
        fee: "",
        gameID: "",
        eventType: "",
        maxPlayers: 0,
        platforms: "",
        rules: "",
        sponsor: "",
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const Update = () => {
        //authenticate here
        if ((values.title == "") || (values.entryText == "") || (values.tier == "") || (values.userID == 0) || (values.content == "") || (values.section == "") || (values.category == "") || ((selectedFile == null) && (img == ""))) {
            Swal.fire("Blog",
                "Required details missing.",
                "error")
            return;
        }
        if (values.title.length > 80) {
            Swal.fire("Blog",
                "Title too long. Should be less than 80 characters.",
                "error")
            return;
        }

        var categories = [];
        categories.push(values.category)
        var params = { title: values.title, entryText: values.entryText, tier: values.tier, content: values.content, authorID: localStorage.getItem("userID"), categories: categories, section: values.section }
        console.log(params)
        axios
            .patch(Global.url + "/article/" + articleID, params, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    addPhoto(articleID)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getEvent = (eventID) => {
        axios
            .get(Global.url + "/event/"+eventID)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    console.log(message)
                    setEvent(message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    function addPhoto(itemID) {

        if ((selectedFile == null) && (img != "")) {
            navigate("/articles")
            return
        }
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "article-" + articleID);
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Article Update", "Article successfully updated.", "success").then(() => {
                        navigate("/articles")
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const openFile = () => {
        inputFile.current.click();
    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        var userID = localStorage.getItem("userID");
        setToken(tokenStr)
        setUserType(userType)
        setUserID(userID)
        if (eventID != undefined) {
            getEvent(eventID)
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update event</Text>
            </Box>
            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                    
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="name" id="name" onChange={onChange} type="text" placeholder="Event name" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea id="description" name="description" onChange={onChange} type="text" placeholder="Event description" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Platforms</FormLabel>
                            <Input name="platforms" id="prize" onChange={onChange} type="text" placeholder="Platforms, comma separated eg playstation, xbox" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Prize</FormLabel>
                            <Input name="prize" id="prize" onChange={onChange} type="text" placeholder="Event prize" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Start Date</FormLabel>
                            <Input name="startDate" id="startDate" onChange={onChange} type="text" placeholder="Start Date" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Fee</FormLabel>
                            <Input name="fee" id="fee" onChange={onChange} type="text" placeholder="Fee" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Game</FormLabel>
                            <Select id="gameID" name="gameID" onChange={onChange} placeholder="Select game" >
                                <option value={1}>Fortnite</option>
                                <option value={2}>PUBG</option>
                                <option value={3}>Call of Duty</option>
                                <option value={4}>FIFA 23</option>
                                <option value={5}>Rocket League</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Event Type</FormLabel> 
                            <Select id="eventType" name="eventType" onChange={onChange} placeholder="Event type" >
                                <option value="Invite Only">Invite Only</option>
                                <option value="Open">Open Registration</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Max Players</FormLabel>
                            <Input name="maxPlayers" id="maxPlayers" onChange={onChange} type="text" placeholder="Maximum players" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Rules</FormLabel>
                            <Textarea id="rules" name="rules" onChange={onChange} type="text" placeholder="Event rules" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Sponsor</FormLabel>
                            <Select id="sponsor" name="sponsor" onChange={onChange} placeholder="Select game" >
                                <option value="nitrade">Nitrade</option>
                                <option value="paid">Paid</option>
                            </Select>
                        </FormControl>
                        <VStack flex={1} align="start" spacing={4}>
                            <Img src={img} onClick={openFile} alt="Click to Select Article Image" w="100px" h="100px" />
                            <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                        </VStack>
                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={Update}>
                                Update Event
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}