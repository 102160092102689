import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

export const ManageTransactions = () => {
    const [token, setToken] = useState('')
    const [transactions, setTransactions] = useState([])
    const [transaction, setTransaction] = useState({ mpesaTransactionID: 0 })
    const [total, setTotal] = useState(0)
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        transactionID: ""
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const searchTransaction = () => {
        console.log(values)
        //authenticate here
        if (values.transactionID == "") {
            Swal.fire("Search Transaction",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .get(Global.url + "/payment/mpesa/transaction/search/" + values.transactionID, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    setTransaction(res.data.message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getTransactions = async (t) => {
        axios
            .get(Global.url + "/payment/mpesa/transaction", { headers: { token: t } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    setTransactions(message.transactions)
                    setTotal(message.total)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const viewService = (e) => {
        var serviceID = e.target.getAttribute("data-id")
        navigate("/view-service", { state: { "serviceID": serviceID } })
    }


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        if(userType == "2"){
            getTransactions(tokenStr)
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Transactions</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        {usertype == "2" ? <Tab>Mpesa TXN({transactions.length})</Tab> : ""}
                        <Tab>Search TXN</Tab>
                    </TabList>
                    <TabPanels>
                        {usertype == "2" ? <TabPanel>
                            <Box w="full" pb={4}>
                                <Text textAlign="start">Total: {total}</Text>
                            </Box>
                            <TableContainer>

                                <Table variant='simple'>
                                    <TableCaption>Transactions</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Type</Th>
                                            <Th>ID</Th>
                                            <Th>Amount</Th>
                                            <Th>Time</Th>
                                            <Th>Ref</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {transactions.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No transactions</Text></Tr> : ""}
                                        {transactions.map((item) => (
                                            <Tr key={item.mpesaTransactionID}>
                                                <Td>{item.TransactionType}</Td>
                                                <Td>{item.TransID}</Td>
                                                <Td>{item.TransAmount}</Td>
                                                <Td>{item.TransTime}</Td>
                                                <Td>{item.BillRefNumber}</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Type</Th>
                                        <Th>ID</Th>
                                        <Th>Amount</Th>
                                        <Th>Time</Th>
                                        <Th>Ref</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                            : ""}
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>Transaction ID</FormLabel>
                                        <Input name="transactionID" onChange={onChange} type="text" placeholder="TXN ID" />
                                    </FormControl>
                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={searchTransaction}>
                                            Search
                                        </Button>
                                    </Box>


                                    {transaction.mpesaTransactionID != 0 ? <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                                        <Text textAlign="start" fontSize={28} fontWeight="bold" >Transaction Details</Text>
                                        <Text textAlign="start">Trans Type: {transaction.TransactionType}</Text>
                                        <Text textAlign="start">Trans ID: {transaction.TransID}</Text>
                                        <Text textAlign="start">Trans Time: {transaction.TransTime}</Text>
                                        <Text textAlign="start">Trans Amount: {transaction.TransAmount}</Text>
                                        <Text textAlign="start">Bill Ref: {transaction.BillRefNumber}</Text>
                                    </Box> : <span>No result</span>}
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}