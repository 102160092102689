import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const ViewAccount = () => {
    const [token, setToken] = useState('')
    const [account, setAccount] = useState({})
    const [accountSubscribers, setAccountSubscribers] = useState([])
    const [usertype, setUserType] = useState("")

    const { state } = useLocation();

    const [values, setValues] = useState({
        name: "",
        platform: "",
        salePrice: 0,
        purchasePrice: 0,
        region: "",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const deleteProduct = () => {

    }
    const updateProduct = () => {

    }


    const getAccountSubscribers = async (accountID,tokenStr) => {
        axios
            .get(Global.url + "/account/" + accountID + "/subscriber", { headers: { token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    // console.log("variants:",message)
                    setAccountSubscribers(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getAccount = async (accountID,tokenStr) => {
        axios
            .get(Global.url + "/account/" + accountID, { headers: { token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAccount(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        if (state != null) {
            if ("accountID" in state) {
                console.log(state.accountID)
                getAccount(state.accountID,tokenStr)
                getAccountSubscribers(state.accountID,tokenStr)
            }
        }

    }, [state])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >View account</Text>

            </Box>

            <Box p={8} w="full">
                <VStack align="start" w="full">
                    <HStack w="full" align="start" spacing={8}>
                        <Flex flex={1} flexDir="column" borderRightColor="gray.100" borderRightWidth={1}>
                            <VStack spacing={6} align="start">
                                <p>Email: <span>{account.email}</span></p>
                                <p>Password: <span>{account.password}</span></p>
                                <p>Renewal Date: <span>{account.renewalDate}</span></p>
                                <p>Package: <span>{account.package}</span></p>
                                <p>Service: <span>{account.service}</span></p>
                                <p>Status: <span>{account.status}</span></p>
                            </VStack>
                            <Box mt={4} w="full" alignItems="center">
                                <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={updateProduct}>
                                    Update
                                </Button>

                                <Button w="200px" variant="outline" mr={4} color="gray" onClick={deleteProduct}>
                                    Delete
                                </Button>
                            </Box>
                        </Flex>
                        <VStack flex={1} align="start" spacing={4}>
                            <Heading fontWeight="normal" fontSize="24px">More Details</Heading>
                            <Text>Slots Available: 0</Text>

                        </VStack>
                    </HStack>



                    <TableContainer pt={8} w="full">
                        <Table variant='simple' w="full">
                            <TableCaption>Account Subscribers</TableCaption>
                            <Thead>
                                <Tr>
                                    <Th>Account Subsciber ID</Th>
                                    <Th>Phone Number</Th>
                                    <Th>Username</Th>
                                    <Th>Profile</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {accountSubscribers.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No account subscribers</Text></Tr> : ""}
                                {accountSubscribers.map((item) => (
                                    <Tr>
                                        <Td>{item.accountSubscriberID}</Td>
                                        <Td>{item.phoneNumber}</Td>
                                        <Td>{item.username}</Td>
                                        <Td>{item.profile}</Td>
                                        <Td>{item.status}</Td>
                                    </Tr>
                                ))
                                }
                            </Tbody>

                            <Tfoot>
                                <Th>Account Subsciber ID</Th>
                                <Th>Phone Number</Th>
                                <Th>Username</Th>
                                <Th>Profile</Th>
                                <Th>Status</Th>
                            </Tfoot>
                        </Table>
                    </TableContainer>

                </VStack>
            </Box>
        </Box>
    )
}