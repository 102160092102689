import { Flex } from "@chakra-ui/react"
import { UpdateProduct } from "../components/product/update-product"
import { Sidebar } from "../components/sidebar/sidebar"

export const UpdateProductPage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateProduct/>
        </Flex>
    )
}