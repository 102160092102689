import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';

export const AccountConfirm = (props) => {
  const [loading, setLoading] = useState(true)
  const [stopSpinner, setStopSpinner] = useState(false)
  const [caption, setCaption] = useState("Confirming your account")
  const [intro, setIntro] = useState("Please wait as we confirm your account")
  const [refID, setRefID] = useState("")
  const navigate = useNavigate()
  const params = useParams();
  useEffect(() => {
    if(params.refID == undefined){
      setCaption("Confirm Account")
      setLoading(false)
      setIntro("Kindly check your inbox to confirm your account. Are you done?")
    }else{
      confirmAccount(params.refID)
    }
  }, [params.refID])

  const proceed = ()=> {
    navigate("/login")
  }
  const confirmAccount = (id) => {
    axios
      .post(Global.url + "/user/confirm/" + id, {})
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        if (res.data.success == 1) {
          setCaption("Confirmation done")
          setLoading(false)
          setIntro("Account successfully confirmed")
        } else {
          Swal.fire("Error", res.data.message, "error");
          setStopSpinner(true)
          setIntro(res.data.message)
          setCaption("Error occured")
        }
      });
  }

  return (
    <Box px={{ lg: '32', sm: "2" }}>
      {/* Navbar */}
      <Navbar />
      {/* Navbar End */}

      {/* Main Content Start */}
      <Box p={4} w="full">
        <Flex
          minH={'90vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}
            align="center">
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              {caption}
            </Heading>
            <FormControl id="password" align="center" isRequired>
              <Text>{intro}</Text>
              <Spinner m={8} display={loading && !stopSpinner ? "flex" : "none"} size='xl' />
            </FormControl>
            <Stack spacing={6}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                display={loading ? "none" : "flex"}
                id="btnProceed"
                onClick={proceed}
              >
                {loading ? "Loading..." : "Proceed to Login"}
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}