import axios from "axios";
import React, { useState, createContext, useContext } from "react";
import { Global } from "../utils/global";

// Create the context 
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authed, setAuth] = useState(false);
    /// Mock Async Login API call.
    // TODO: Replace with your actual login API Call code
    // const fakeAsyncLogin = async () => {
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             resolve("Logged In");
    //         }, 300);
    //     });
    // };

    const login = async (params) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                axios
                    .post(Global.url + "/login", params)
                    .catch(err => {
                        reject(err)
                    }).then(res => {
                        if (res.data.success == 1) {
                            setAuth(true);
                        } else {
                            setAuth(false);
                        }
                        resolve(res)
                    });
            }, 300);
        });
    };

    return (
        // Using the provider so that ANY component in our application can 
        // use the values that we are sending.
        <AuthContext.Provider value={{ authed, setAuth, login }}>
            {children}
        </AuthContext.Provider>
    );
};

// Finally creating the custom hook 
export const useAuth = () => useContext(AuthContext);