import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const UpdateSubscriptionItem = () => {
    const [token, setToken] = useState('')
    const [serviceID, setServiceID] = useState(0)
    const [service, setService] = useState({ name: "" })
    const [subscriptionItem, setSubscriptionItem] = useState({})
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        period: "",
        package: "p",
        price: 0
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateItem = () => {
        console.log(values)
        //authenticate here
        if ((values.price == 0) || (values.period == "") || (values.package == "")) {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .patch(Global.url + "/subscription/item/" + subscriptionItem.subscriptionID + "/update", { "price": values.price, "period": values.period, "package": values.package }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Subscription Item",
                        "Updated successfully.",
                        "success").then(() => {
                            navigate("/view-service", { state: { "serviceID": serviceID } })
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getSubscriptionItem = async (id) => {
        axios
            .get(Global.url + "/subscription/item/" + id)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setSubscriptionItem(message)
                    values.period = message.period
                    document.getElementById("period").value = message.period
                    document.getElementById("price").value = message.price
                    document.getElementById("package").value = message.package

                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getService = async (serviceID) => {
        axios
            .get(Global.url + "/subscription/service/" + serviceID)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setService(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)

        if (state != null) {
            if ("subscriptionItemID" in state) {
                getSubscriptionItem(state.subscriptionItemID)
            }
            if ("serviceID" in state) {
                setServiceID(state.serviceID)
                getService(state.serviceID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update subscription item</Text>

            </Box>

            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <Flex flexDir="column">
                            <span>Name: {service.name}</span>
                        </Flex>
                        <FormControl>
                            <FormLabel>Period</FormLabel>
                            <Select name="period" id="period" onChange={onChange} placeholder="Select period" >
                                <option value="1 month">1 month</option>
                                <option value="3 months">3 months</option>
                                <option value="6 months">6 months</option>
                                <option value="12 months">1 year</option>
                            </Select>
                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                        </FormControl>
                        {service.name == "dstv" ?
                            <FormControl>
                                <FormLabel>Package</FormLabel>
                                <Select name="package" id="package" onChange={onChange} placeholder="Select " >
                                    <option value="c">Compact</option>
                                    <option value="c+">Compact Plus</option>
                                    <option value="p">Premium</option>
                                </Select>
                                {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                            </FormControl> : ""}
                        <FormControl>
                            <FormLabel>Price</FormLabel>
                            <Input name="price" id="price" onChange={onChange} type="number" placeholder="Price" />
                        </FormControl>
                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateItem}>
                                Update Item
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}