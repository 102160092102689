import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Icon,
    Text,
    HStack,
    Flex,
    Heading,
    VStack,
    Button,
    FormControl,
    FormLabel,
    Input,
    IconButton,
    Avatar,
    Select,
    Tag,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

import { FiArrowUpRight, FiCheck, FiMail } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Global } from '../../utils/global'
import Swal from 'sweetalert2'
import { Qualification } from './qualification'
import { useRef } from 'react'
import { useAuth } from '../../context/authContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { Line } from "react-chartjs-2";
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    registerables,
} from "chart.js"

ChartJS.register(...registerables);

export const Dashboard = (props) => {
    const { authed } = useAuth();
    const navigate = useNavigate()
    const [crr, setCrr] = useState(0)
    const [churn, setChurn] = useState(0)
    const [clv, setClv] = useState(0)
    const [userType, setUserType] = useState(0)
    const [labels, setLabels] = useState([])
    const [serviceMetricLabels, setServiceMetricLabels] = useState([])
    const [subscriptionMetricLabels, setSubscriptionMetricLabels] = useState([])
    const [counts, setCounts] = useState([])
    const [serviceMetricCounts, setServiceMetricCounts] = useState([])
    const [subscriptionMetricCounts, setSubscriptionMetricCounts] = useState([])
    const [subscriptionMetricAmounts, setSubscriptionMetricAmounts] = useState([])
    const [data, setData] = useState({
        labels: labels,
        datasets: [
            {
                label: "User Metrics",
                data: counts,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    })
    const [servicesMetrics, setServicesMetrics] = useState({
        labels: serviceMetricLabels,
        datasets: [
            {
                label: "User Metrics",
                data: serviceMetricCounts,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    })
    const [subscriptionMetrics, setSubscriptionMetrics] = useState({
        labels: subscriptionMetricLabels,
        datasets: [
            {
                label: "User Metrics",
                data: subscriptionMetricCounts,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }, {
                label: "User Metrics",
                data: subscriptionMetricAmounts,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    })
    const [subscriptionAmountsMetrics, setSubscriptionAmountsMetrics] = useState({
        labels: subscriptionMetricLabels,
        datasets: [
            {
                label: "User Metrics",
                data: subscriptionMetricAmounts,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    })
    const [values, setValues] = useState({
        city: "",
        country: "",
        currency: "",
        fee: 0,
        program: "",
        university: "",
    })

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const getUserMetrics = async (strToken) => {
        var tempLabels = []
        var vals = []

        axios
            .get(Global.url + "/user/metrics/12M", { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message.metrics
                    for (var i = 0; i < message.length; i++) {
                        var metric = message[i]
                        var arr = metric.end.split(" ")
                        var date = arr[0]
                        // var metric = { "date": date, "date": date }
                        tempLabels.push(date)
                        vals.push(metric.count)
                    }
                    setLabels(tempLabels)
                    setCounts(vals)
                    setData({
                        labels: tempLabels,
                        datasets: [
                            {
                                label: "User Metrics",
                                data: vals,
                                fill: true,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor: "rgba(75,192,192,1)"
                            }
                        ]
                    })
                    getServiceMetrics(strToken)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };
    const getServiceMetrics = async (strToken) => {
        var labels = []
        var vals = []

        axios
            .get(Global.url + "/subscription/service/metrics", { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setServiceMetricLabels(message.labels)
                    setServiceMetricCounts(message.counts)
                    setServicesMetrics({
                        labels: message.labels,
                        datasets: [
                            {
                                label: "Service Metrics",
                                data: message.counts,
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                    'rgba(153, 102, 255, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)',
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)',
                                    'rgba(75, 192, 192, 1)',
                                    'rgba(153, 102, 255, 1)',
                                    'rgba(255, 159, 64, 1)',
                                    'rgba(255, 159, 64, 1)',
                                ]
                            }
                        ]
                    })
                    getSubscriptionMetrics(strToken)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };
    const getCrr = async (strToken) => {
        var labels = []
        var vals = []

        axios
            .get(Global.url + "/subscription/retention", { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    var c = ((message.activeSub-message.newSub) / message.startSub) * 100
                    setCrr(c)
                    setChurn(100 - (Math.round(c * 10) / 10))
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };
    const getSubscriptionMetrics = async (strToken) => {
        var labels = []
        var vals = []
        var amounts = []

        axios
            .get(Global.url + "/subscription/metrics/12M", { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    for (var i = 0; i < message.metrics.length; i++) {
                        var metric = message.metrics[i];
                        labels.push(metric.end.split(" ")[0])
                        vals.push(metric.count)
                        amounts.push(metric.amount)
                    }
                    setSubscriptionMetricLabels(labels)
                    setSubscriptionMetricCounts(vals)
                    setSubscriptionMetricAmounts(amounts)
                    setSubscriptionMetrics({
                        labels: labels,
                        datasets: [
                            {
                                label: "New subscriptions",
                                data: vals,
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgba(255, 99, 132, 1)'
                            }
                        ]
                    })
                    setSubscriptionAmountsMetrics({
                        labels: labels,
                        datasets: [
                            {
                                label: "Amount Metrics",
                                data: amounts,
                                backgroundColor: 'rgba(54, 162, 235, 1)',
                                borderColor: 'rgba(255, 206, 86, 1)'
                            }
                        ]
                    })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });

    };
    useEffect(() => {
        // getEvents()
        console.log("auth", authed)
        var strToken = localStorage.getItem("token");
        var userType = localStorage.getItem("userType");
        if (userType == "" || userType == undefined || (strToken == "")) {
            //go to login
            navigate("/login")
        } else {
            setUserType(userType)
        }
        getUserMetrics(strToken)
        getCrr(strToken)
    }, [])



    // function addFee() {
    //     var userID = localStorage.getItem("userID")

    //     var params = { "currency": values.currency, "fee": values.fee }
    //     axios
    //         .post(Global.url + "/provider/" + userID + "/fee", params)
    //         .catch(err => {
    //             console.log(err)
    //         }).then(res => {
    //             if (res.data.success == 1) {
    //                 Swal.fire("Fee Update", res.data.message, "success").then(() => {
    //                     if (profileState.availability) {
    //                         addAvailability()
    //                     }
    //                 })
    //             } else {
    //                 Swal.fire("Fee Update", res.data.message, "error");
    //             }
    //         });
    // }

    return (
        <Box
            flex={1}
            h="auto"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" py={4} >
                <HStack flexDir="row" w="full" >
                    <Box p={4} flex = "1" borderBottomColor="gray.100" borderBottomWidth={1} borderRightWidth={1} borderRightColor="gray.100">
                        <Flex w="full" flexDir="row">
                            <Box flex="3">
                                <VStack w="full" align="flex-start">
                                    <Flex w="full" flexDir="row" align="flex-start">
                                        <Text fontSize={20} fontWeight="semibold">CRR</Text>
                                        <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                    </Flex>
                                    {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                    <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Customer Retention Rate</Text>
                                </VStack>
                            </Box>
                            <Box flex="1">
                                <Flex w="full" h="full" flexDir="column" align="flex-end">
                                    <HStack>
                                        <Text fontSize={36} fontWeight="semibold">{Math.round(crr * 10) / 10}%</Text>
                                        <Text fontSize={16} fontWeight="semibold"> 35 days CRR</Text>
                                    </HStack>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box p={4} flex = "1"  borderBottomColor="gray.100" borderBottomWidth={1} borderRightWidth={1} borderRightColor="gray.100">
                        <Flex w="full" flexDir="row">
                            <Box flex="3">
                                <VStack w="full" align="flex-start">
                                    <Flex w="full" flexDir="row" align="flex-start">
                                        <Text fontSize={20} fontWeight="semibold">Churn</Text>
                                        <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                    </Flex>
                                    {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                    <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Customer Churn Rate</Text>
                                </VStack>
                            </Box>
                            <Box flex="1">
                                <Flex w="full" h="full" flexDir="column" align="flex-end">
                                    <HStack>
                                        <Text fontSize={36} fontWeight="semibold">{churn}%</Text>
                                        <Text fontSize={16} fontWeight="semibold"> 35 days Churn</Text>
                                    </HStack>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box p={4} flex = "1" borderBottomColor="gray.100" borderBottomWidth={1}>
                        <Flex w="full" flexDir="row">
                            <Box flex="3">
                                <VStack w="full" align="flex-start">
                                    <Flex w="full" flexDir="row" align="flex-start">
                                        <Text fontSize={20} fontWeight="semibold">CLV</Text>
                                        <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Days</Text>
                                    </Flex>
                                    {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                    <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Customer Lifetime Value</Text>
                                </VStack>
                            </Box>
                            <Box flex="1">
                                <Flex w="full" h="full" flexDir="column" align="flex-end">
                                    <HStack>
                                        <Text fontSize={36} fontWeight="semibold">{clv}%</Text>
                                        <Text fontSize={16} fontWeight="semibold"> CLV </Text>
                                    </HStack>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </HStack>
                <Flex dir='row' p={4}>
                    <VStack flex={2} dir="row" p={4}  borderWidth={1} borderColor="gray.100"  mr={8}>
                        <Heading fontWeight="normal" fontSize="24px">New Users Per Week(1 Year)</Heading>
                        {counts.length == 0 ? " " : <Line data={data} />}
                    </VStack>
                    <VStack flex={1} p={4}  borderWidth={1} borderColor="gray.100">
                        <Heading fontWeight="normal" fontSize="24px">Users per service</Heading>
                        {serviceMetricCounts.length == 0 ? " " : <Pie data={servicesMetrics} />}
                    </VStack>
                </Flex>
                <Flex dir='row' p={4} marginTop={6}>
                    <VStack flex={2} borderWidth={1} p={4}  borderColor="gray.100" mr={8}>
                        <Heading fontWeight="normal" fontSize="24px">Subscriptions Per Week(1 Year)</Heading>

                        {subscriptionMetricCounts.length == 0 ? " " : <Line data={subscriptionMetrics} />}
                    </VStack>
                    {userType == 2 ?
                        <VStack flex={2} p={4} borderWidth={1} borderColor="gray.100">
                            {/* {serviceMetricCounts.length == 0 ? " " : <Pie data={servicesMetrics} />} */}
                            <Heading fontWeight="normal" fontSize="24px">Total amount Per Week(1 Year)</Heading>
                            {subscriptionMetricAmounts.length == 0 ? " " : <Line data={subscriptionAmountsMetrics} />}
                        </VStack> : ""
                    }
                </Flex>
            </Box>
        </Box>
    )
}