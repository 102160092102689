import { Flex } from "@chakra-ui/react"
import { ViewProduct } from "../components/product/view-product"
import { UpdateSubscriptionItem } from "../components/service/update-subscription-item"
import { Sidebar } from "../components/sidebar/sidebar"

export const SubscriptionItemUpdatePage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateSubscriptionItem/>
        </Flex>
    )
}