import { Box, Button, Flex, FormControl, FormLabel, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { useRef } from "react"

export const ManageEvents = () => {
    // const {eventID} =useLocation()
    const inputFile = useRef(null)
    const [img, setImg] = useState("assets/images/eject.png");
    const [selectedFile, setSelectedFile] = useState(null);

    const styles = {
        editor: {
            border: '1px solid gray',
            minHeight: '6em'
        }
    };
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [token, setToken] = useState('')
    // const [transaction, setTransaction] = useState({ mpesaTransactionID: 0 })
    const [userID, setUserID] = useState(0)
    const [usertype, setUserType] = useState("")
    const [events, setEvents] = useState([])
    const [values, setValues] = useState({
        title: "",
        content: "",
        section: "other",
        category: "news",
        tier: "",
        summary: "",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const addArticle = () => {
        let html = stateToHTML(editorState.getCurrentContent());
        if (html != "") {
            values.content = html
        }
        // console.log(values)

        //authenticate here
        if ((values.title == "") || (values.summary == "") || (values.content == "") || (values.tier == "") || (values.section == "") || (values.category == "")) {
            Swal.fire("Add Article",
                "Required details missing.",
                "error")
            return;
        }
        if (values.title.length > 80) {
            Swal.fire("Blog",
                "Title too long. Should be less than 80 characters.",
                "error")
            return;
        }
        var categories = [];
        categories.push(values.category)
        var params = { title: values.title, entryText: values.summary, tier: values.tier, content: values.content, authorID: localStorage.getItem("userID"), categories: categories, section: values.section }
        axios
            .post(Global.url + "/article", params, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    addPhoto(res.data.message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getEvents = () => {
        axios
            .get(Global.url + "/event")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    console.log(message)
                    setEvents(message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const updateArticle = (e) => {
        var articleID = e.target.getAttribute("data-id")
        navigate("/update-article", { state: { "articleID": articleID } })
    }
    function addPhoto(itemID) {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "article-" + itemID);
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Update", "Article successfully updated.", "success").then(() => {
                        window.location.reload()
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const openFile = () => {
        inputFile.current.click();
    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var userID = localStorage.getItem("userID");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        setUserID(userID)
        // if(eventID!=undefined){
        // get event
        getEvents()
        // }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Events</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>Events({events.length})</Tab>
                        <Tab>Create Event</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TableContainer>
                                <Table variant='simple'>
                                    <TableCaption>Events</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Description</Th>
                                            <Th>Platforms</Th>
                                            <Th>Prize</Th>
                                            <Th>Start Date</Th>
                                            <Th>Fee</Th>
                                            <Th>Game</Th>
                                            <Th>Event Type</Th>
                                            <Th>Max Players</Th>
                                            <Th>Rules</Th>
                                            <Th>Status</Th>
                                            <Th>Sponsor</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {events.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No events</Text></Tr> : ""}
                                        {events.map((item) => (
                                            <Tr key={item.articleID}>
                                                <Td>{item.title.slice(0, 15) + "..."}</Td>
                                                {/* dangerouslySetInnerHTML={{__html: data}} */}
                                                <Td dangerouslySetInnerHTML={{ __html: item.content.slice(0, 15) + "..." }} />
                                                <Td>{item.author}</Td>
                                                <Td>{item.section}</Td>
                                                <Td dangerouslySetInnerHTML={{ __html: item.entryText.slice(0, 15) + "..." }} />
                                                <Td>{item.categories}</Td>
                                                <Td>{item.tier}</Td>
                                                {/* <Td>{item.comments}</Td> */}
                                                <Td>{item.likes}</Td>
                                                <Td>{item.dateAdded}</Td>
                                                <Td data-id={item.articleID}
                                                    onClick={updateArticle}>Update</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Name</Th>
                                        <Th>Description</Th>
                                        <Th>Platforms</Th>
                                        <Th>Prize</Th>
                                        <Th>Start Date</Th>
                                        <Th>Fee</Th>
                                        <Th>Game</Th>
                                        <Th>Event Type</Th>
                                        <Th>Max Players</Th>
                                        <Th>Rules</Th>
                                        <Th>Status</Th>
                                        <Th>Sponsor</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">

                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input name="name" id="name" onChange={onChange} type="text" placeholder="Event name" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea id="description" name="description" onChange={onChange} type="text" placeholder="Event description" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Platforms</FormLabel>
                                        <Input name="platforms" id="platforms" onChange={onChange} type="text" placeholder="Platforms, comma separated eg playstation, xbox" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Prize</FormLabel>
                                        <Input name="prize" id="prize" onChange={onChange} type="text" placeholder="Event prize" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Start Date</FormLabel>
                                        <Input name="startDate" id="startDate" onChange={onChange} type="text" placeholder="Start Date" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Fee</FormLabel>
                                        <Input name="fee" id="fee" onChange={onChange} type="text" placeholder="Fee" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Game</FormLabel>
                                        <Select id="gameID" name="gameID" onChange={onChange} placeholder="Select game" >
                                            <option value={1}>Fortnite</option>
                                            <option value={2}>PUBG</option>
                                            <option value={3}>Call of Duty</option>
                                            <option value={4}>FIFA 23</option>
                                            <option value={5}>Rocket League</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Event Type</FormLabel>
                                        <Select id="eventType" name="eventType" onChange={onChange} placeholder="Event type" >
                                            <option value="Invite Only">Invite Only</option>
                                            <option value="Open">Open Registration</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Max Players</FormLabel>
                                        <Input name="maxPlayers" id="maxPlayers" onChange={onChange} type="text" placeholder="Maximum players" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Rules</FormLabel>
                                        <Textarea id="rules" name="rules" onChange={onChange} type="text" placeholder="Event rules" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Sponsor</FormLabel>
                                        <Select id="sponsor" name="sponsor" onChange={onChange} placeholder="Select game" >
                                            <option value="nitrade">Nitrade</option>
                                            <option value="paid">Paid</option>
                                        </Select>
                                    </FormControl>
                                    <VStack flex={1} align="start" spacing={4}>
                                        <Img src={img} onClick={openFile} alt="Click to Select Article Image" w="100px" h="100px" />
                                        <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                                    </VStack>
                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={addArticle}>
                                            Update Event
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}