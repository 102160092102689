import { Flex } from "@chakra-ui/react"
import { UpdateEvent } from "../components/event/update-event"
import { Sidebar } from "../components/sidebar/sidebar"

export const UpdateEventPage = () => {
    return (
        <Flex>
            <Sidebar />
            <UpdateEvent/>
        </Flex>
    )
}
