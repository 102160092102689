import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Login } from './pages/login';
import { SignUp } from './pages/signup';
import { ForgotPassword } from './pages/forgot-password';
import { ResetPassword } from './pages/reset-password';
import axios from 'axios';
import { Global } from './utils/global';
import Swal from 'sweetalert2';
import { DashboardPage } from './pages/dashboard-page';
import { ProductsPage } from './pages/products-page';
import { BillingPage } from './pages/billing-page';
import { AccountConfirm } from './pages/account-confirm';
import mixpanel from 'mixpanel-browser';
import { ServicesPage } from './pages/services-page';
import { UsersPage } from './pages/users-page';
import { SubscriptionsPage } from './pages/subscriptions-page';
import { AccountsPage } from './pages/accounts-page';
import { ViewProductPage } from './pages/view-product-page';
import { ViewAccountPage } from './pages/view-account-page';
import { ViewServicePage } from './pages/view-service-page';
import { SubscriptionItemUpdatePage } from './pages/subscription-item-update-page';
import { UpdateUserPage } from './pages/update-user-page';
import { UpdateServicePage } from './pages/update-service-page';
import { UpdateProductPage } from './pages/update-product-page';
import { UpdateVariantPage } from './pages/update-variant-page';
import { SalesPage } from './pages/sales-page';
import { OperationsPage } from './pages/operations-page';
import { TransactionsPage } from './pages/transactions-page';
import { ArticlesPage } from './pages/articles-page';
import { UpdateArticlePage } from './pages/update-article-page';
import { EventPage } from './pages/event-page';
import { UpdateEventPage } from './pages/update-event-page';
import { GamesPage } from './pages/games-page';
mixpanel.init(Global.mixpanelToken,{
  debug:true
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      specialities: [],
      doctors: [],
      locations: []
    }
  }

  // getSpecialities() {
  //   axios
  //     .get(Global.url + "/speciality")
  //     .catch(err => {
  //       // what now?
  //       console.log(err);
  //     }).then(res => {
  //       console.log(res);
  //       if (res.data.success == 1) {
  //         this.setState({ specialities: res.data.message })
  //       } else {
  //         Swal.fire("Error", res.data.message, "error");
  //       }
  //     });
  
  // };

  componentDidMount() {
    // this.getSpecialities()
    // this.getProviders()
    // this.getLocations()
  }
  render() {
    return (
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* Start of protected routes */}
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/event" element={<EventPage />} />
            <Route path="/event/:eventID/update" element={<UpdateEventPage />} />
            <Route path="/view-service" element={<ViewServicePage />} />
            <Route path="/view-product" element={<ViewProductPage />} />
            <Route path="/update-variant" element={<UpdateVariantPage />} />
            <Route path="/view-account" element={<ViewAccountPage />} />
            <Route path="/articles" element={<ArticlesPage />} />
            <Route path="/update-article" element={<UpdateArticlePage />} />
            <Route path="/sales" element={<SalesPage />} />
            <Route path="/operations" element={<OperationsPage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/accounts" element={<AccountsPage />} />
            <Route path="/billing" element={<BillingPage />} />
            <Route path="/account/confirm/:refID" element={<AccountConfirm />} />
            <Route path="/account/confirm" element={<AccountConfirm />} />
            <Route path="/subscription/item/update" element={<SubscriptionItemUpdatePage />} />
            <Route path="/user/update" element={<UpdateUserPage />} />
            <Route path="/service/update" element={<UpdateServicePage />} />
            <Route path="/product/update" element={<UpdateProductPage />} />
          </Routes>
        </Router>
      </ChakraProvider>
    );
  }
}

export default App;
