import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const ManageProduct = () => {
    const [token, setToken] = useState('')
    const [products, setProducts] = useState([])
    const [sales, setSales] = useState([])
    const [variants, setVariants] = useState([])
    const [usertype, setUserType] = useState("")

    const [values, setValues] = useState({
        name: "",
        expense: 0,
        paid: 0,
        productID: -1,
        variantID: -1,
        phoneNumber: "",
        username: "",
        category: "",
        itemType: "",
        summary: "",
        area: "",
        city: "",
        street: "",
        status: "pending",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const RefreshVariants = () => {
        if (values.productID != -1) {
            getVariants(values.productID)
        } else {
            Swal.fire("Variants",
                "Select product first.",
                "error")
        }
    }

    const AddSale = () => {
        console.log(values)
        //authenticate here
        // "username", "phoneNumber", "items", "expense", "city", "area", "street", "paid", "status"
        if ((values.phoneNumber == "") || (values.street == "") || (values.paid == "") || (values.variantID == -1)) {
            Swal.fire("Sale",
                "Required details missing.",
                "error")
            return;
        }
        var item = { "itemID": values.variantID, "quantity": 1, "itemType": "product" }
        var items = []
        items.push(item)
        axios
            .post(Global.url + "/product/item/sale", { "phoneNumber": values.phoneNumber, "street": values.street, "area": values.area, "city": values.city, "paid": values.paid, "items": items, "username": values.username, "expense": values.expense , "status": values.status }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Sale",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    const AddProduct = () => {
        console.log(values)
        //authenticate here
        if ((values.summary == "") || (values.name == "") || (values.category == "") || (values.itemType == "")) {
            Swal.fire("Product",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/product/item", { "summary": values.summary, "name": values.name, "category": values.category, "itemType": values.itemType }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Product",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    const viewProduct = (e) => {
        var itemID = e.target.getAttribute("data-id")
        navigate("/view-product", { state: { "itemID": itemID } })
    }

    const getVariants = async (itemID) => {
        axios
            .get(Global.url + "/product/item/" + itemID + "/variant")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setVariants(message)
                    if(message.length != 0){
                        values.variantID = message[0].variantID
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const getSales = async (strToken) => {
        axios
            .get(Global.url + "/product/item/sale", { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setSales(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getProducts = async () => {
        axios
            .get(Global.url + "/product/item")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message.products
                    // console.log("Products:",message)
                    setProducts(message)
                    if(message.length != 0){
                        values.productID = message[0].itemID
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getProducts()
        getSales(tokenStr)
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Manage products</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>List({products.length})</Tab>
                        <Tab>Create</Tab>
                        <Tab>Create Order</Tab>
                        <Tab>Orders({sales.length})</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TableContainer>
                                <Table variant='simple'>
                                    <TableCaption>Product Items</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Order ID</Th>
                                            <Th>Variant ID</Th>
                                            <Th>Summary</Th>
                                            <Th>Item Type</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {products.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No products</Text></Tr> : ""}
                                        {products.map((item) => (
                                            <Tr>
                                                <Td>{item.name}</Td>
                                                <Td>{item.category}</Td>
                                                <Td>{item.summary.substring(0, 15)} {"..."}</Td>
                                                <Td>{item.itemType}</Td>
                                                <Td data-id={item.itemID}
                                                    onClick={viewProduct}>View</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Name</Th>
                                        <Th>Category</Th>
                                        <Th>Summary</Th>
                                        <Th>Item Type</Th>
                                        <Th>Action</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>

                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input name="name" onChange={onChange} type="text" placeholder="Product name" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Category</FormLabel>
                                        <Select name="category" onChange={onChange} placeholder="Product category" >
                                            <option value="sale">Sale</option>
                                            <option value="upcoming">Upcoming</option>
                                            <option value="deal">Deal</option>
                                            <option value="recent">Recent Releases</option>
                                            <option value="trending">Trending</option>
                                            <option value="exclusive">Nitrade Exclusive</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Summary</FormLabel>
                                        <Input name="summary" onChange={onChange} type="text" placeholder="Product summary" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Item Type</FormLabel>
                                        <Select name="itemType" onChange={onChange} placeholder="Item type" >
                                            <option value="giftcard">Giftcard</option>
                                            <option value="gamecard">Gamecard</option>
                                            <option value="device">Device</option>
                                            <option value="merchandise">Merchandise</option>
                                        </Select>
                                    </FormControl>
                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddProduct}>
                                            Add Product
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>IG Username</FormLabel>
                                        <Input name="username" onChange={onChange} type="text" placeholder="IG username" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Phone Number*</FormLabel>
                                        <Input name="phoneNumber" onChange={onChange} type="text" placeholder="Phone Number" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Product</FormLabel>
                                        <Select name="productID" onChange={onChange}>
                                            {products.map((product) => (
                                                <option value={product.itemID}>{product.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Variants({variants.length})</FormLabel>
                                        <HStack>
                                            <Select flex={11} name="variantID" onChange={onChange}>
                                                {variants.map((variant) => (
                                                    <option value={variant.variantID}>{variant.name}</option>
                                                ))}
                                            </Select>
                                            <Button w="200px" flex={1} variant="outline" colorScheme="teal.500" onClick={RefreshVariants}>
                                                Refresh
                                            </Button>
                                        </HStack>
                                    </FormControl>


                                    <FormControl>
                                        <FormLabel>Expense*</FormLabel>
                                        <Input name="expense" onChange={onChange} type="number" placeholder="Expense amount eg delivery cost etc" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Delivery Area</FormLabel>
                                        <Input name="area" onChange={onChange} type="text" placeholder="Delivery Area" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Delivery Address*</FormLabel>
                                        <Input name="street" onChange={onChange} type="text" placeholder="Customer street(Mandatory)" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>City</FormLabel>
                                        <Input name="city" onChange={onChange} type="text" placeholder="city" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Amount Paid*</FormLabel>
                                        <Input name="paid" onChange={onChange} type="number" placeholder="Amount Paid" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Status</FormLabel>
                                        <Select name="status" onChange={onChange}>
                                            <option value="pending">Pending</option>
                                            <option value="completed">Completed</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <span>Fields with * are Mandatory</span>
                                    </FormControl>
                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddSale}>
                                            Add Order
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <TableContainer>
                                <Table variant='simple'>
                                    <TableCaption>Product Items</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Order ID</Th>
                                            <Th>Phone Number</Th>
                                            <Th>Username</Th>
                                            <Th>Variant</Th>
                                            <Th>Expense</Th>
                                            <Th>Status</Th>
                                            <Th>Amount</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {sales.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No orders</Text></Tr> : ""}
                                        {sales.map((item) => (
                                            <Tr>
                                                <Td>{item.orderID}</Td>
                                                <Td>{item.phoneNumber}</Td>
                                                <Td>{item.username}</Td>
                                                <Td>{item.variantName}</Td>
                                                <Td>{item.expense}</Td>
                                                <Td>{item.status}</Td>
                                                <Td>{item.amount}</Td>
                                                <Td data-id={item.orderID}
                                                    onClick={viewProduct}>View</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Order ID</Th>
                                        <Th>Variant ID</Th>
                                        <Th>User ID</Th>
                                        <Th>Expense</Th>
                                        <Th>Status</Th>
                                        <Th>Amount</Th>
                                        <Th>Action</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}