import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"
import { useRef } from "react"

export const ViewService = () => {
    const [img, setImg] = useState("images/eject.png");
    const [selectedFile, setSelectedFile] = useState(null);
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    const inputFile = useRef(null)
    const [token, setToken] = useState('')
    const [serviceID, setServiceID] = useState(-1)
    const [service, setService] = useState({})
    const [variants, setVariants] = useState([])
    const [usertype, setUserType] = useState("")

    const { state } = useLocation();

    const [values, setValues] = useState({
        package: "p",
        period: "",
        price: 0,
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const deleteService = () => {

    }
    const updateService = () => {
        navigate("/service/update", { state: { "serviceID": serviceID } })
    }
    const AddVariant = () => {

        console.log(values)
        //authenticate here
        if ((serviceID == -1) || (values.period == "") || (values.price == 0)) {
            Swal.fire("Variant",
                "Required details missing.",
                "error")
            return;
        }
        
        axios
            .post(Global.url + "/subscription/item/variant", { "serviceProfileID": serviceID, "period": values.period, "price": values.price, "package": values.package }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Variant",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getVariants = async (itemID) => {
        axios
            .get(Global.url + "/subscription/item/" + itemID + "/variant")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    // console.log("variants:",message)
                    setVariants(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getService = async (serviceID) => {
        axios
            .get(Global.url + "/subscription/service/" + serviceID)
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setService(message)
                    setImg(Global.awsS3url+"/images/"+message.image)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        if (state != null) {
            if ("serviceID" in state) {
                console.log(state.serviceID)
                getService(state.serviceID)
                getVariants(state.serviceID)
                setServiceID(state.serviceID)
            }
        }

    }, [state])
    function updateImage() {
        addPhoto(serviceID)
    }
    const openFile = () => {
        inputFile.current.click();
    }
    function addPhoto(itemID) {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "service-" + itemID);
        
        // var params = { "name": values.city, "country": values.country, "userID": userID, "type": userType }
        axios
            .post(Global.url + "/item/image/upload" , formData, { headers: { "Content-Type": "multipart/form-data",token:token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Photo Update", res.data.message, "success").then(() => {
                        navigate("/view-service", { state: { "serviceID": itemID } })
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const viewUpdateSubscription = (e) => {
        var subscriptionItemID = e.target.getAttribute("data-id")
        navigate("/subscription/item/update", { state: { "serviceID": serviceID, "subscriptionItemID": subscriptionItemID } })
    }

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >View service</Text>
            </Box>
            <Box p={8} w="full">
                <VStack align="start" w="full">
                    <HStack w="full" align="start" spacing={8}>
                        <Flex flex={1} flexDir="column" borderRightColor="gray.100" borderRightWidth={1}>
                            <VStack spacing={6} align="start">
                                <p>Name: <span>{service.name}</span></p>
                                <p>Description: <span>{service.description}</span></p>
                                <p>Slots: <span>{service.slots}</span></p>
                            </VStack>
                            <Box mt={4} w="full" alignItems="center">
                                <Button w="200px" variant="outline" mr={4} colorScheme="teal.500" onClick={updateService}>
                                    Update
                                </Button>

                                <Button w="200px" variant="outline" mr={4} color="gray" onClick={deleteService}>
                                    Delete
                                </Button>
                            </Box>
                        </Flex>
                        <VStack flex={1} align="start" spacing={4}>
                            <Heading fontWeight="normal" fontSize="24px">More Details</Heading>
                            <Text>Sales: 0</Text>
                            Image:<Img src={img} alt="Product Image" onClick={openFile} w="64px" h="64px" />
                            <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                            <Button variant="outline" onClick={updateImage} mr={4} color="gray" >
                                Update Image
                            </Button>
                        </VStack>
                    </HStack>


                    <Tabs pt={8} w="full">
                        <TabList>
                            <Tab>Variants</Tab>
                            <Tab>Create Variant</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <TableCaption>Product Variants</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Period</Th>
                                                <Th>Package</Th>
                                                <Th>Price</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {variants.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No Variants</Text></Tr> : ""}
                                            {variants.map((item) => (
                                                <Tr>
                                                    <Td>{item.period}</Td>
                                                    <Td>{item.package}</Td>
                                                    <Td>{item.price}</Td>
                                                    <Td data-id={item.subscriptionID} onClick={viewUpdateSubscription}>Update</Td>
                                                    <Td>Delete</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Period</Th>
                                            <Th>Price</Th>
                                            <Th>Action</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel>
                                <Flex flexDir="column" >
                                    <VStack spacing={6} align="start">
                                        <FormControl>
                                            <FormLabel>Period</FormLabel>
                                            <Select name="period" onChange={onChange} placeholder="Select period" >
                                                <option value="1 month">1 month</option>
                                                <option value="3 months">3 months</option>
                                                <option value="6 months">6 months</option>
                                                <option value="12 months">1 year</option>
                                            </Select>
                                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                                        </FormControl>
                                        {service.name == "dstv" ?
                                            <FormControl>
                                                <FormLabel>Period</FormLabel>
                                                <Select name="package" onChange={onChange} placeholder="Select " >
                                                    <option value="c">Compact</option>
                                                    <option value="c+">Compact Plus</option>
                                                    <option value="p">Premium</option>
                                                </Select>
                                                {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                                            </FormControl> : ""}
                                        <FormControl>
                                            <FormLabel>Price</FormLabel>
                                            <Input name="price" onChange={onChange} type="number" placeholder="Price" />
                                        </FormControl>
                                        <Box mt={4} w="full" alignItems="center">
                                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddVariant}>
                                                Add Variant
                                            </Button>
                                        </Box>
                                    </VStack>
                                </Flex>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </VStack>
            </Box>
        </Box>
    )
}