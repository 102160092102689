import {
    Box,
    Flex,
    HStack,
    IconButton,
    Button,
    useDisclosure,
    useColorModeValue,
    Stack,
    Link,
    Img
} from '@chakra-ui/react';

import { Link as ReactLink } from 'react-router-dom';

import {
    HamburgerIcon,
    CloseIcon
} from '@chakra-ui/icons';

import { Logo } from './logo';


const Links = [];

const NavLink = (props) => (
    <Link
        px={2}
        py={1}
        rounded={'md'}
        href={props.link.href}
        _hover={{
            textDecoration: 'none',
            bg: useColorModeValue('gray.200', 'gray.700'),
        }}
    >
        {props.link.caption}
    </Link>
);

export const Navbar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box px={4} borderBottomColor="gray.100" borderBottomWidth={1}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                <IconButton
                    size={'md'}
                    icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                    aria-label={'Open Menu'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                />
                <HStack spacing={8} alignItems={'center'}>
                    <Box>
                        {/* <Logo color={useColorModeValue('gray.700', 'white')} /> */}
                        <Link href="/">
                            <Img w="48px" h="48px" src={require("../logo.png")} />
                        </Link>


                    </Box>
                    <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {Links.map((link) => (
                            <NavLink key={link} link={link} />
                        ))}
                    </HStack>
                </HStack>
                <Flex alignItems={'center'}>
                    <Button
                        variant={'outline'}
                        colorScheme={'teal'}
                        size={'sm'}
                        mr={4}>
                        <Link p={2} style={{ "text-decoration": "none" }} href="/login">
                            SIGN IN
                        </Link>

                    </Button>
                    <Button
                        variant={'solid'}
                        colorScheme={'teal'}
                        size={'sm'}>
                        <Link p={2} style={{ "text-decoration": "none" }} href="/register">
                            SIGN UP
                        </Link>

                    </Button>
                    {/* <Menu visibility="hidden">
                        <MenuButton
                            as={Button}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            minW={0}>
                            <Avatar
                                size={'sm'}
                                src={
                                    'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                }
                            />
                        </MenuButton>
                        <MenuList>
                            <MenuItem>Link 1</MenuItem>
                            <MenuItem>Link 2</MenuItem>
                            <MenuDivider />
                            <MenuItem>Link 3</MenuItem>
                        </MenuList>
                    </Menu> */}
                </Flex>
            </Flex>

            {isOpen ? (
                <Box pb={4} display={{ md: 'none' }}>
                    <Stack as={'nav'} spacing={4}>
                        {Links.map((link) => (
                            <NavLink link={link}>{link.caption}</NavLink>
                        ))}
                    </Stack>
                </Box>
            ) : null}
        </Box>
    )
}