import { Flex } from "@chakra-ui/react"
import { ViewService } from "../components/service/view-service"
import { Sidebar } from "../components/sidebar/sidebar"

export const ViewServicePage = () => {
    return (
        <Flex>
            <Sidebar />
            <ViewService/>
        </Flex>
    )
}