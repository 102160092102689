import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Toast, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { useRef } from "react"
import toast, { Toaster } from "react-hot-toast"

export const ManageGames = () => {
    const { eventID } = useLocation()
    const inputFile = useRef(null)
    const [img, setImg] = useState("assets/images/eject.png");
    const [selectedFile, setSelectedFile] = useState(null);

    const styles = {
        editor: {
            border: '1px solid gray',
            minHeight: '6em'
        }
    };
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [token, setToken] = useState('')
    const [games, setGames] = useState([])
    const [gameResults, setGameResults] = useState([])
    const [game, setGame] = useState({ "id": 0 })
    const [selectedGame, setSelectedGame] = useState({ "id": 0,"name":"" })
    // const [transaction, setTransaction] = useState({ mpesaTransactionID: 0 })
    const [userID, setUserID] = useState(0)
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        searchText: "",
        name: "",
        genre: "",
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const addGame = () => {
        if (selectedGame.id == 0) {
            Swal.fire("Error", "Game has not been selected", "error");
            return
        }

        var params = { game: selectedGame }
        axios.post(Global.url + "/game", params, { headers: { token: token } }).catch(err => {
            // what now?
            console.log(err);
        }).then(res => {
            if (res.data.success == 1) {
                Swal.fire("Game", res.data.message, "success");
                // addPhoto(res.data.message);
            } else {
                Swal.fire("Error", res.data.message, "error");
            }
        });
    }

    const getGames = (eventID) => {
        axios
            .get(Global.url + "/game")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    console.log(message)
                    setGames(message);
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const updateGame = (e) => {
        var articleID = e.target.getAttribute("data-id")
        navigate("/update-article", { state: { "articleID": articleID } })
    }
    function addPhoto(itemID) {
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "article-" + itemID);
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Update", "Article successfully updated.", "success").then(() => {
                        window.location.reload()
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const openFile = () => {
        inputFile.current.click();
    }
    const selectGame = (e) => {
        var id = e.target.getAttribute("data-id")
        for (var i = 0; i < gameResults.length; i++) {
            var tempGame = gameResults[i];
            if (tempGame.id == id) {
                toast.success("Game selected")
                setSelectedGame(tempGame);
                break
            }
        }

    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    const searchGames = async () => {
        axios
            .get(Global.url + "/game/search/" + values.searchText, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setGameResults(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var userID = localStorage.getItem("userID");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        setUserID(userID)
        getGames()
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Games</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs>
                    <TabList>
                        <Tab>Games({games.length})</Tab>
                        <Tab>Create Game</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TableContainer>
                                <Table variant='simple'>
                                    <TableCaption>Games</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Genre</Th>
                                            <Th>Platforms</Th>
                                            <Th>No. Of Events</Th>
                                            <Th>Date Added</Th>
                                            <Th>Image</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {games.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No games found</Text></Tr> : ""}
                                        {games.map((item) => (
                                            <Tr key={item.id}>
                                                <Td>{item.name}</Td>
                                                <Td>{item.genre}</Td>
                                                <Td>{item.platforms}</Td>
                                                <Td></Td>
                                                {/* <Td>{item.eventCount}</Td> */}
                                                <Td>{item.dateAdded}</Td>
                                                <Td><img w="48px" h="48px" src={""} /></Td>
                                                <Td data-id={item.id}
                                                    onClick={updateGame}>Update</Td>
                                            </Tr>
                                        ))
                                        }
                                    </Tbody>
                                    <Tfoot>
                                        <Th>Name</Th>
                                        <Th>Genre</Th>
                                        <Th>Platforms</Th>
                                        <Th>No. Of Events</Th>
                                        <Th>Date Added</Th>
                                        <Th>Image</Th>
                                        <Th>Action</Th>
                                    </Tfoot>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack w="full" align="start" borderWidth={1} borderColor="gray.100" my={8} p={8} >
                                    <FormControl>
                                        <Text>Search game</Text>
                                        <HStack padding={8} w="full">
                                            <Input flex="11" name="searchText" type="text" onChange={onChange} placeholder="Search game by name" />
                                            <Button flex="1" w="200px" py={4} variant="outline" colorScheme="teal.500" onClick={searchGames}>
                                                Search
                                            </Button>
                                        </HStack>
                                    </FormControl>
                                    <TableContainer w="full">
                                        <Table variant='simple' w="full">
                                            <TableCaption>Search Results</TableCaption>
                                            <Thead>
                                                <Tr>
                                                    <Th>Name</Th>
                                                    {/* <Th>Genre</Th> */}
                                                    <Th>Platforms</Th>
                                                    {/* <Th>Image</Th> */}
                                                    <Th>Action</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {gameResults.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No game found</Text></Tr> : ""}
                                                {gameResults.map((item) => (
                                                    <Tr>
                                                        <Td>{item.name}</Td>
                                                        {/* <Td>{item.genre}</Td> */}
                                                        <Td>{item.platforms.toString()}</Td>
                                                        {/* <Td></Td> */}
                                                        {/* <Td>{item.dateAdded}</Td> */}
                                                        {/* <Td><img w="48px" h="48px" src={""} /></Td> */}
                                                        <Td data-id={item.id}
                                                            onClick={selectGame}>Select</Td>
                                                    </Tr>
                                                ))
                                                }
                                            </Tbody>
                                            <Tfoot>
                                                <Th>Name</Th>
                                                {/* <Th>Genre</Th> */}
                                                <Th>Platforms</Th>
                                                {/* <Th>Image</Th> */}
                                                <Th>Action</Th>
                                            </Tfoot>
                                        </Table>
                                    </TableContainer>
                                </VStack>

                                <VStack borderWidth={1} borderColor="gray.100" my={8} p={8} spacing={6} align="start">
                                    <Text>Confirm details below are correct and proceed create game, no need to edit</Text>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input name="name" id="name" value={selectedGame.name} onChange={onChange} type="text" placeholder="Game name" />
                                    </FormControl>
                                    <Button w="200px" variant="outline" colorScheme="teal.500" onClick={addGame}>
                                        Add Game
                                    </Button>
                                </VStack>
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}