import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect, useRef } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"

export const UpdateVariant = () => {
    const [token, setToken] = useState('')
    const [itemID, setItemID] = useState(0)
    const [variant, setVariant] = useState({})
    const [usertype, setUserType] = useState("")
    const [img, setImg] = useState("images/eject.png");
    const [selectedFile, setSelectedFile] = useState(null);
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    const inputFile = useRef(null)
    const openFile = () => {
        inputFile.current.click();
    }

    const [values, setValues] = useState({
        name: "",
        platform: "",
        salePrice: 0,
        purchasePrice: 0,
        region: ""
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateItem = () => {
        console.log(values)
        //authenticate here
        if ((selectedFile == null) && (img == "")) {
            Swal.fire("Image",
                "Required image is missing.",
                "error")
            return;
        }
        if ((values.platform == "") || (values.name == "") || (values.salePrice == 0) || (values.purchasePrice == 0) || (values.region == "")) {
            Swal.fire("Product",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .patch(Global.url + "/product/item/variant/" + itemID, { "name": values.name, "platform": values.platform, "region": values.region, "purchasePrice": values.purchasePrice + "", "salePrice": values.salePrice }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    if ((selectedFile != null)) {
                        addPhoto(itemID)
                    } else {
                        Swal.fire("Variant",
                            "Updated successfully.",
                            "success").then(() => {
                                navigate("/view-product", { state: { "itemID": variant.itemID } })
                            })
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getVariant = async (id) => {
        var tokenStr = localStorage.getItem("token");

        axios
            .get(Global.url + "/product/item/variant/" + id + "", { headers: { Token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                if (res.data.success == 1) {
                    var message = res.data.message
                    document.getElementById("name").value = message.name
                    document.getElementById("platform").value = message.platform
                    document.getElementById("salePrice").value = message.salePrice
                    document.getElementById("purchasePrice").value = message.purchasePrice
                    document.getElementById("region").value = message.region
                    values.name = message.name
                    values.platform = message.platform
                    values.salePrice = message.salePrice
                    values.purchasePrice = message.purchasePrice
                    values.region = message.region
                    if (message.image != "") {
                        setImg(Global.awsS3url + "/images/" + message.image)
                    }
                    setVariant(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    // function updateImage() {
    //     addPhoto(itemID)
    // }

    function addPhoto(itemID) {
        if ((selectedFile == null) && (img == "")) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "variant-" + itemID);
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Variant",
                        "Updated successfully.",
                        "success").then(() => {
                            navigate("/view-product", { state: { "itemID": variant.itemID } })
                        })
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }

    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)

        if (state != null) {
            if ("itemID" in state) {
                setItemID(state.itemID)
                getVariant(state.itemID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update variant</Text>

            </Box>

            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="name" id="name" onChange={onChange} type="text" placeholder="Variant Name" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Platform</FormLabel>
                            <Select id="platform" name="platform" onChange={onChange} placeholder="Select platform" >
                                <option value="playstation">playstation</option>
                                <option value="xbox">xbox</option>
                                <option value="nintendo">nintendo</option>
                                <option value="pc">pc</option>
                                <option value="amazon">amazon</option>
                                <option value="google">google</option>
                                <option value="microsoft">Microsoft</option>
                                <option value="meta">Meta</option>
                                <option value="apple">apple</option>
                                <option value="roku">roku</option>
                                <option value="redmi">redmi</option>
                                <option value="samsung">samsung</option>
                                <option value="jbl">JBL</option>
                                <option value="huawei">Huawei</option>
                                <option value="anker">Anker</option>
                                <option value="mi">Mi</option>
                                <option value="onyx">Onyx</option>
                                <option value="windows">windows</option>
                                <option value="oneplus">oneplus</option>
                                <option value="starlink">startlink</option>
                                <option value="dell">dell</option>
                                <option value="hp">hp</option>
                                <option value="lenovo">Lenovo</option>
                                <option value="asus">Asus</option>
                                <option value="lg">LG</option>
                                <option value="sony">Sony</option>
                                <option value="nvidia">Nvidia</option>
                                <option value="hotec">Hotec</option>
                                <option value="jabra">Jabra</option>
                                <option value="toshiba">Toshiba</option>
                                <option value="bose">Bose</option>
                                <option value="dell">dell</option>
                                <option value="celestron">celestron</option>
                                <option value="night-owl">Night Owl</option>
                                <option value="apexel">Apexel</option>
                                <option value="seetec">Seetec</option>
                                <option value="logitech">Logitech</option>
                                <option value="elgato">Elgato</option>
                                <option value="pioneer">Pioneer</option>
                                <option value="pyle">Pyle</option>
                                <option value="subrent">Subrent</option>
                                <option value="coman">Coman</option>
                                <option value="feelword">Feelworld</option>
                                <option value="sandisk">Sandisk</option>
                                <option value="owc">OWC</option>
                                <option value="G-Technology">G-Technology</option>
                                <option value="gosky">Gosky</option>
                                <option value="holy-stone">Holy Stone</option>
                                <option value="nikon">Nikon</option>
                                <option value="kodak">Kodak</option>
                                <option value="netgear">Netgear</option>
                                <option value="blackmagic">Blackmagic</option>
                                <option value="blackvue">Blackvue</option>
                                <option value="mirabox">Mirabox</option>
                                <option value="cable-matters">Cable Matters</option>
                                <option value="pluggable">Pluggable</option>
                                <option value="tamron">Tamron</option>
                                <option value="benfiss">Benfiss</option>
                                <option value="my-cloud">MyCloud</option>
                                <option value="panasonic">Panasonic</option>
                                <option value="duracell">Duracell</option>
                                <option value="polarduck">polarduck</option>
                                <option value="thinkdiag">thinkdiag</option>
                                <option value="NOCO">NOCO</option>
                                <option value="amcrest">Amcrest</option>
                                <option value="blue-drive">Blue Drive</option>
                                <option value="thinkdriver">Thinkdriver</option>
                                <option value="arteck">Arteck</option>
                                <option value="canon">Canon</option>
                                <option value="uperfect">Uperfect</option>
                                <option value="j-tech">J-Tech</option>
                                <option value="plantronics">Plantronics</option>
                                <option value="wavlink">Wavlink</option>
                                <option value="Tiffen">Tiffen</option>
                                <option value="other">Other</option>
                            </Select>
                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>Purchase Price</FormLabel>
                            <Input id="purchasePrice" name="purchasePrice" onChange={onChange} type="text" placeholder="Purchase Price" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Sale Price</FormLabel>
                            <Input id="salePrice" name="salePrice" onChange={onChange} type="text" placeholder="Sale Price" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Region</FormLabel>
                            <Select id="region" name="region" onChange={onChange} placeholder="Select region" >
                                <option value="uae">United Arab Emirates</option>
                                <option value="usa">USA</option>
                                <option value="uk">United Kingdom</option>
                                <option value="sa">South Africa</option>
                                <option value="global">global</option>
                            </Select>
                            {/* <Input name="category" onChange={onChange} type="text" placeholder="Product category" /> */}
                        </FormControl>
                        <VStack flex={1} align="start" spacing={4}>
                            <Heading fontWeight="normal" fontSize="24px">Image</Heading>
                            <Img src={img} onClick={openFile} alt="Variant Image" w="100px" h="100px" />
                            <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                        </VStack>

                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateItem}>
                                Update Variant
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}