import { Flex } from "@chakra-ui/react"
import { ManageArticles } from "../components/articles/manage-blog"
import { Sidebar } from "../components/sidebar/sidebar"
import { ManageTransactions } from "../components/transactions/manage-transactions"

export const ArticlesPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageArticles/>
        </Flex>
    )
}
