import { Flex } from "@chakra-ui/react"
import { Sidebar } from "../components/sidebar/sidebar"
import { ManageTransactions } from "../components/transactions/manage-transactions"

export const TransactionsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageTransactions/>
        </Flex>
    )
}
