import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const ManageSubscription = () => {
    const [token, setToken] = useState('')
    const [status, setStatus] = useState('true')
    const [statusFilter, setStatusFilter] = useState('true')
    const [subscriptions, setSubscriptions] = useState([])
    const [upcomingSubscriptions, setUpcomingSubscriptions] = useState([])
    const [expiredSubscriptions, setExpiredSubscriptions] = useState([])
    const [userSubscriptions, setUserSubscriptions] = useState([])
    const [services, setServices] = useState([])
    const [availableAccounts, setAvailableAccounts] = useState([])
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        username: "",
        phoneNumber: "",
        profile: "",
        searchPhone: "",
        service: "",
        account: -1,
        package: "",
        duration: "0",
        filterDuration: 1,

        filterSubByPhoneNumber: "",
        filterExpiredSubByPhoneNumber: "",
        selectedSubscription: -1,
    })

    const navigate = useNavigate()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }
    const FilterUpcomingRenewals = () => {
        getUpcomingRenewals(token)
    }
    const FilterSubByPhoneNumber = () => {
        getSubscriptionsByPhone(token,values.filterSubByPhoneNumber)
    }
    const FilterExpiredSubByPhoneNumber = () => {
        setStatusFilter("false")
        getSubscriptionsByPhone(token,values.filterExpiredSubByPhoneNumber)
    }
    const SubscriptionAttachAccount = () => {
        console.log(values)
        //authenticate here
        if ((values.selectedSubscription == -1) || (values.account == -1)) {
            Swal.fire("Subscription",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/subscription/account/attach", { "profile": values.profile, "accountID": values.account, "subscriptionID": values.selectedSubscription }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Attach Account",
                        "Account successfully attached to subscription.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }
    const AddSubscription = () => {
        console.log(values)
        //authenticate here
        if ((values.phoneNumber == "") || (values.service == "") || (values.duration == "0")) {
            Swal.fire("Subscription",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .post(Global.url + "/subscription", { "phoneNumber": values.phoneNumber, "username": values.username, "service": values.service, "duration": values.duration, "package": values.package }, { headers: { Token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");

                    Swal.fire("Subscription",
                        "Created successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", "Error occurred when creating subscription", "error");
                }
            });
    }

    const searchActiveUserSubscriptions = async () => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/search/" + values.searchPhone + "/true", { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setUserSubscriptions(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const refreshAccount = async () => {
        if (values.selectedSubscription == -1) {
            Swal.fire("Attach account",
                "Subscription has not been selected.",
                "error")
            return;
        }
        //get subscription
        var service = ""
        for (var i = 0; i < userSubscriptions.length; i++) {
            var subscription = userSubscriptions[i]
            if (subscription.subscriptionID == values.selectedSubscription) {
                service = subscription.service
            }
        }
        if (service == "") {
            Swal.fire("Attach account",
                "Subscription has not been selected.",
                "error")
            return;
        }


        axios
            .get(Global.url + "/account/" + service + "/withslots", { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setAvailableAccounts(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    const getSubscriptions = async (strToken, strStatus) => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/" + strStatus, { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    if (strStatus == "true") {
                        setSubscriptions(message)
                    } else {
                        setExpiredSubscriptions(message)
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const getUpcomingRenewals = async (strToken) => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/upcoming/" + values.filterDuration, { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setUpcomingSubscriptions(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const getSubscriptionsByPhone = async (strToken,phone) => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/search/" + phone + "/" + statusFilter, { headers: { token: strToken } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    if(statusFilter == "true"){
                        setSubscriptions(message)
                    }else{
                        setExpiredSubscriptions(message)
                    }
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };
    const getServices = async () => {
        console.log("token: ", token)
        axios
            .get(Global.url + "/subscription/item")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setServices(message)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        getSubscriptions(tokenStr, "true")
        setStatus("false")
        getSubscriptions(tokenStr, "false")
        getUpcomingRenewals(tokenStr)
        getServices()
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Manage subscriptions</Text>
            </Box>

            <Box p={8} w="full">
                <Tabs w="full">
                    <TabList>
                        <Tab>Active({subscriptions.length})</Tab>
                        <Tab>Create</Tab>
                        <Tab>Attach Account</Tab>
                        <Tab>Upcoming Renewals({upcomingSubscriptions.length})</Tab>
                        <Tab>Expired({expiredSubscriptions.length})</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <VStack w="full" align="start">
                                <FormControl w="full">
                                    <HStack padding={8} w="full">
                                        <Input flex="11" name="filterSubByPhoneNumber" type="text" onChange={onChange} placeholder="Search by phone number eg +254705....." />
                                        <Button flex="1" w="200px" variant="outline" colorScheme="teal.500" onClick={FilterSubByPhoneNumber}>
                                            Search
                                        </Button>
                                    </HStack>
                                </FormControl>
                                <TableContainer w="full">
                                    <Table variant='simple' w="full">
                                        <TableCaption>subscriptions</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Subscription ID</Th>
                                                <Th>Name</Th>
                                                <Th>Phone Number</Th>
                                                <Th>Service</Th>
                                                <Th>Period</Th>
                                                <Th>Expiry</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {subscriptions.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No subscriptions</Text></Tr> : ""}
                                            {subscriptions.map((item) => (
                                                <Tr>
                                                    <Td>{item.subscriptionID}</Td>
                                                    <Td>{item.name}</Td>
                                                    <Td>{item.phoneNumber}</Td>
                                                    <Td>{item.service}</Td>
                                                    <Td>{item.period}</Td>
                                                    <Td>{item.expiry}</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Subscription ID</Th>
                                            <Th>Name</Th>
                                            <Th>Phone Number</Th>
                                            <Th>Service</Th>
                                            <Th>Period</Th>
                                            <Th>Expiry</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </VStack>
                        </TabPanel>
                        <TabPanel>
                            <Flex flexDir="column" >
                                <VStack spacing={6} align="start">
                                    <FormControl>
                                        <FormLabel>IG Username</FormLabel>
                                        <Input name="username" onChange={onChange} type="email" placeholder="IG username" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Phone Number</FormLabel>
                                        <Input name="phoneNumber" onChange={onChange} type="email" placeholder="Phone Number" />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Service</FormLabel>
                                        <Select name="service" onChange={onChange} placeholder="Service">
                                            {services.map((service) => (
                                                <option value={service.name}>{service.name}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Duration</FormLabel>
                                        <Select name="duration" onChange={onChange} placeholder="Duration">
                                            <option value="1">1 month</option>
                                            <option value="3">3 months</option>
                                            <option value="6">6 months</option>
                                        </Select>
                                    </FormControl>
                                    {values.service == "dstv" ? <FormControl>
                                        <FormLabel>Package</FormLabel>
                                        <Select name="package" onChange={onChange} placeholder="Package eg premium" >
                                            <option value="compact">Compact</option>
                                            <option value="premium">Premium</option>
                                        </Select>
                                    </FormControl> : ""}

                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={AddSubscription}>
                                            Add Subscription
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                        <TabPanel w="full">
                            <Flex flexDir="column" w="full">
                                <VStack spacing={6} align="start" w="full">
                                    <FormControl>
                                        <FormLabel>Phone Number</FormLabel>
                                        <HStack>
                                            <Input name="searchPhone" flex={11} onChange={onChange} type="text" placeholder="Search subscription by phone number" />
                                            <Button w="200px" flex={1} variant="outline" colorScheme="teal.500" onClick={searchActiveUserSubscriptions}>
                                                Search
                                            </Button>
                                        </HStack>
                                    </FormControl>
                                    <TableContainer pt={8} w="full">
                                        <Table variant='simple' w="full">
                                            <TableCaption>Select subscription to attach account</TableCaption>
                                            <Thead>
                                                <Tr>
                                                    <Th>Select</Th>
                                                    <Th>Subscription ID</Th>
                                                    <Th>Name</Th>
                                                    <Th>Phone Number</Th>
                                                    <Th>Service</Th>
                                                    <Th>Period</Th>
                                                    <Th>Expiry</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {userSubscriptions.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No user subscriptions</Text></Tr> : ""}
                                                {userSubscriptions.map((item) => (
                                                    <Tr>
                                                        <Td><input name="selectedSubscription" onChange={onChange} value={item.subscriptionID} type="radio" /></Td>
                                                        <Td>{item.subscriptionID}</Td>
                                                        <Td>{item.name}</Td>
                                                        <Td>{item.phoneNumber}</Td>
                                                        <Td>{item.service}</Td>
                                                        <Td>{item.period}</Td>
                                                        <Td>{item.expiry}</Td>
                                                    </Tr>
                                                ))
                                                }
                                            </Tbody>
                                            <Tfoot>
                                                <Tr>
                                                    <Th>Select</Th>
                                                    <Th>Subscription ID</Th>
                                                    <Th>Name</Th>
                                                    <Th>Phone Number</Th>
                                                    <Th>Service</Th>
                                                    <Th>Period</Th>
                                                    <Th>Expiry</Th>
                                                </Tr>
                                            </Tfoot>
                                        </Table>
                                    </TableContainer>
                                    <FormControl>
                                        <FormLabel>Select account({availableAccounts.length})</FormLabel>
                                        <HStack>
                                            <Select name="account" flex={11} onChange={onChange} placeholder="Account">
                                                {availableAccounts.map((account) => (
                                                    <option value={account.accountID}>{account.email}</option>
                                                ))}
                                            </Select>
                                            <Button w="200px" flex={1} variant="outline" colorScheme="teal.500" onClick={refreshAccount}>
                                                Refresh
                                            </Button>
                                        </HStack>

                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Profile Name</FormLabel>
                                        <Input name="profile" onChange={onChange} type="text" placeholder="Profile Name" />
                                    </FormControl>

                                    <Box mt={4} w="full" alignItems="center">
                                        <Button w="200px" variant="outline" colorScheme="teal.500" onClick={SubscriptionAttachAccount}>
                                            Attach Account
                                        </Button>
                                    </Box>
                                </VStack>
                            </Flex>
                        </TabPanel>
                        <TabPanel>
                            <VStack w="full" align="start">
                                <FormControl>
                                    <HStack padding={8} w="full">
                                        <Input flex="11" name="filterDuration" type="number" onChange={onChange} placeholder="Duration in weeks eg 1" />
                                        <Button flex="1" w="200px" variant="outline" py={4} colorScheme="teal.500" onClick={FilterUpcomingRenewals}>
                                            Filter
                                        </Button>
                                    </HStack>
                                </FormControl>
                                <TableContainer w="full">
                                    <Table variant='simple' w="full">
                                        <TableCaption>Upcoming subscriptions renewals({values.filterDuration} week(s))</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Subscription ID</Th>
                                                <Th>Name</Th>
                                                <Th>Phone Number</Th>
                                                <Th>Service</Th>
                                                <Th>Period</Th>
                                                <Th>Expiry</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {upcomingSubscriptions.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No upcoming subscription renewals</Text></Tr> : ""}
                                            {upcomingSubscriptions.map((item) => (
                                                <Tr>
                                                    <Td>{item.subscriptionID}</Td>
                                                    <Td>{item.name}</Td>
                                                    <Td>{item.phoneNumber}</Td>
                                                    <Td>{item.service}</Td>
                                                    <Td>{item.period}</Td>
                                                    <Td>{item.expiry}</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Subscription ID</Th>
                                            <Th>Name</Th>
                                            <Th>Phone Number</Th>
                                            <Th>Service</Th>
                                            <Th>Period</Th>
                                            <Th>Expiry</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </VStack>

                        </TabPanel>
                        <TabPanel>
                            <VStack w="full" align="start">
                                <FormControl>
                                    <HStack padding={8} w="full">
                                        <Input flex="11" name="filterExpiredSubByPhoneNumber" type="text" onChange={onChange} placeholder="Search phone number eg +254705..." />
                                        <Button flex="1" w="200px" variant="outline" colorScheme="teal.500" onClick={FilterExpiredSubByPhoneNumber}>
                                            Search
                                        </Button>
                                    </HStack>
                                </FormControl>
                                <TableContainer w="full">
                                    <Table variant='simple' w="full">
                                        <TableCaption>expired subscriptions</TableCaption>
                                        <Thead>
                                            <Tr>
                                                <Th>Subscription ID</Th>
                                                <Th>Name</Th>
                                                <Th>Phone Number</Th>
                                                <Th>Service</Th>
                                                <Th>Period</Th>
                                                <Th>Expiry</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {expiredSubscriptions.length == 0 ? <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">No expired subscriptions</Text></Tr> : ""}
                                            {expiredSubscriptions.map((item) => (
                                                <Tr>
                                                    <Td>{item.subscriptionID}</Td>
                                                    <Td>{item.name}</Td>
                                                    <Td>{item.phoneNumber}</Td>
                                                    <Td>{item.service}</Td>
                                                    <Td>{item.period}</Td>
                                                    <Td>{item.expiry}</Td>
                                                </Tr>
                                            ))
                                            }
                                        </Tbody>
                                        <Tfoot>
                                            <Th>Subscription ID</Th>
                                            <Th>Name</Th>
                                            <Th>Phone Number</Th>
                                            <Th>Service</Th>
                                            <Th>Period</Th>
                                            <Th>Expiry</Th>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </VStack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Box>
    )
}