import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Navbar } from '../components/navbar';
import { Global } from '../utils/global';

export const ResetPassword = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [values, setValues] = useState({
    code: "",
    password: "",
    confirmPassword: "",
  })
  const onChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }


  useEffect(() => {
    if (state != null) {
      if ("phoneNumber" in state) {
        setPhoneNumber(state.phoneNumber)
      }
    }
  }, [state])

  const resetPassword = () => {
    if (values.password != values.confirmPassword) {
      Swal.fire("Error", "Password are not matching", "error");
      return;
    }

    axios
      .post(Global.url + "/user/auth", { phoneNumber: phoneNumber, code: values.code, password: values.password })
      .catch(err => {
        // what now?
        console.log(err);
      }).then(res => {
        console.log(res);
        if (res.data.success == 1) {
          Swal.fire("Password Changed", res.data.message, "success").then(() => {
            navigate("/login")
          });
        } else {
          Swal.fire("Error", res.data.message, "error");
        }
      });
  }
  return (
    <Box px={{ lg: '32', sm: "2" }}>
      {/* Navbar */}
      <Navbar />
      {/* Navbar End */}

      {/* Main Content Start */}
      <Box p={4} w="full">
        <Flex
          minH={'90vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={12}>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              Enter new password
            </Heading>
            <FormControl id="phoneNumber" isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="Phone number eg +2547..."
                _placeholder={{ color: 'gray.500' }}
                type="text"
                name="phone"
                id="phone"
                value={phoneNumber}
                disabled
              />
            </FormControl>

            <FormControl id="code" isRequired>
              <FormLabel>Code</FormLabel>
              <Input type="text" name="code" onChange={onChange} />
            </FormControl>
            
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" onChange={onChange} />
            </FormControl>
            <FormControl id="confirmPassword" isRequired>
              <FormLabel>Confirm Password</FormLabel>
              <Input type="password" name="confirmPassword" onChange={onChange} />
            </FormControl>
            <Stack spacing={6}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={resetPassword}>
                Submit
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}