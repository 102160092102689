import { Flex } from "@chakra-ui/react"
import { Sidebar } from "../components/sidebar/sidebar"
import { ManageUser } from "../components/user/manage-user"

export const UsersPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageUser/>
        </Flex>
    )
}