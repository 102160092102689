import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Box,
    Icon,
    Text,
    HStack,
    Flex,
    Heading,
    VStack,
    Button,
} from '@chakra-ui/react'

import { FiArrowUpRight, FiCheck, FiMail } from 'react-icons/fi'

export const Billing = () => {
    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Billing</Text>
                <Text textAlign="start" fontSize={20} fontWeight="normal" color="gray.500" >Manage your billing and payment details</Text>
            </Box>
            <Box p={8}>
                <Flex flexDir="row" >
                    <Box m={4} flex="1" borderColor="gray.100" borderWidth={1}>
                        <VStack>
                            <Box p={4} w="full" borderBottomColor="gray.100" borderBottomWidth={1}>
                                <Flex w="full" flexDir="row">
                                    <Box flex="3">
                                        <VStack w="full" align="flex-start">
                                            <Flex w="full" flexDir="row" align="flex-start">
                                                <Text fontSize={20} fontWeight="semibold">Basic plan</Text>
                                                <Text fontSize={14} fontWeight="semibold" color="green.500" p={2} ml={2} rounded="full" backgroundColor="gray.100">Monthly</Text>
                                            </Flex>
                                            {/* <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">You are currently subscribed</Text> */}
                                            <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Not subscribed</Text>
                                        </VStack>
                                    </Box>
                                    <Box flex="1">
                                        <Flex w="full" h="full" flexDir="column" align="flex-end">
                                            <HStack>
                                                <Text fontSize={36} fontWeight="semibold">$10</Text>
                                                <Text fontSize={16} fontWeight="semibold">/ month</Text>
                                            </HStack>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box p={4} w="full" alignContent="flex-end">
                                <Button p={2} variant="link">
                                    Upgade Plan
                                    <Icon ml={2} as={FiArrowUpRight} />
                                </Button>
                            </Box>
                        </VStack>
                    </Box>
                    <Box m={4} flex="1" p={4} borderColor="gray.100" borderWidth={1}>
                        <VStack w="full" align="flex-start">
                            <Text fontSize={20} fontWeight="semibold">Payment method</Text>
                            <Text mt={8} fontSize={16} fontWeight="normal" color="gray.500">Change how you can pay for your plan</Text>
                            <Box w="full" h="full" mt={4} borderRadius={8} borderWidth={1} borderColor="gray.100" p={4}>
                                <Flex flexDir="row" w="full" h="auto">
                                    <Box w="16" h="16" bgColor="gray.100" flex={1} />
                                    <Flex flexDir="row" flex={6}>
                                        <VStack px="4" align="flex-start">
                                            <Text fontSize={16} fontWeight="bold" color="gray.500">Visa ending in ****</Text>
                                            <Text fontSize={16} fontWeight="normal" color="gray.500">Expiry **/****</Text>
                                            <HStack>
                                                <Icon as={FiMail} />
                                                <Text fontSize={16} fontWeight="normal" color="gray.500">Expiry **/****</Text>
                                            </HStack>
                                        </VStack>
                                    </Flex>
                                    <Button variant="outline" colorScheme="teal" flex={1}>
                                        Edit
                                    </Button>
                                </Flex>
                            </Box>
                        </VStack>
                    </Box>
                </Flex>
            </Box>
            <Box p={8} w="full">
                <TableContainer>
                    <Table variant='simple'>
                        <TableCaption>Client billing</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Invoice</Th>
                                <Th>Billing Date</Th>
                                <Th>Status</Th>
                                <Th>Amount</Th>
                                <Th>Plan</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr alignItems="center" w="full"><Text m={4} w="full" align="center">Not yet billed</Text></Tr>
                            {/* <Tr>
                                <Td>INV-1</Td>
                                <Td>30/03/2022</Td>
                                <Td color="green"><Icon as={FiCheck} /> paid</Td>
                                <Td>$10</Td>
                                <Td>Bronze</Td>
                                <Td color="green">Download</Td>
                            </Tr>
                            <Tr>
                                <Td>INV-2</Td>
                                <Td>30/04/2022</Td>
                                <Td color="green"><Icon as={FiCheck} /> paid</Td>
                                <Td>$10</Td>
                                <Td>Bronze</Td>
                                <Td color="green">Download</Td>
                            </Tr>
                            <Tr>
                                <Td>INV-3</Td>
                                <Td>30/05/2022</Td>
                                <Td color="green"><Icon as={FiCheck} /> paid</Td>
                                <Td>$10</Td>
                                <Td>Bronze</Td>
                                <Td color="green">Download</Td>
                            </Tr> */}
                        </Tbody>
                        <Tfoot>
                            <Th>Invoice</Th>
                            <Th>Billing Date</Th>
                            <Th>Status</Th>
                            <Th>Amount</Th>
                            <Th>Plan</Th>
                            <Th></Th>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}