import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import Rating from "react-rating"

export const UpdateUser = () => {
    const [token, setToken] = useState('')
    const [user, setUser] = useState({})
    const [usertype, setUserType] = useState("")
    const [values, setValues] = useState({
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
    })
    const navigate = useNavigate()
    const {state} = useLocation()
    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateUser = () => {
        console.log(values)
        //authenticate here
        if (values.phoneNumber == "") {
            Swal.fire("Service",
                "Required details missing.",
                "error")
            return;
        }
        axios
            .patch(Global.url + "/user/"+user.userId+"/update", { "username": values.username,  "firstName": values.firstName,  "lastName": values.lastName, "phoneNumber": values.phoneNumber, "email": values.email }, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    // swal("Login", "Login success", "success");
                    Swal.fire("User Update",
                        "User updated successfully.",
                        "success").then(() => {
                            window.location.reload(false);
                        })
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

  
    const getUser = async (id) => {
        var tokenStr = localStorage.getItem("token");

        axios
            .get(Global.url + "/user/" + id+"/get", { headers: { token: tokenStr } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    setUser(message)
                    document.getElementById("username").value = message.alias
                    document.getElementById("firstName").value = message.firstName
                    document.getElementById("lastName").value = message.lastName
                    document.getElementById("phoneNumber").value = message.phoneNumber
                    document.getElementById("email").value = message.email
                    values.username= message.alias
                    values.firstName= message.firstName
                    values.lastName= message.lastName
                    values.phoneNumber= message.phoneNumber
                    values.email= message.email
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };


    useEffect(() => {
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        setToken(tokenStr)
        setUserType(userType)
        if(state!=null){
            if("userID" in state){
                getUser(state.userID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update user</Text>

            </Box>
            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <FormControl>
                            <FormLabel>IG Username</FormLabel>
                            <Input name="username" id ="username" onChange={onChange} type="text" placeholder="IG Username" />
                        </FormControl>
                        <Flex flexDir={"row"}>
                            <FormControl flex="1" w="full">
                                <FormLabel>First Name</FormLabel>
                                <Input name="firstName" id="firstName" onChange={onChange} type="text" placeholder="First name" />
                            </FormControl>
                            <FormControl flex="1"  w="full">
                                <FormLabel>Last Name</FormLabel>
                                <Input name="lastName" id="lastName" onChange={onChange} type="text" placeholder="Last name" />
                            </FormControl>
                        </Flex>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input name="email" id="email" onChange={onChange} type="email" placeholder="User email" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <Input name="phoneNumber" id="phoneNumber" onChange={onChange} type="text" placeholder="Phone Number" />
                        </FormControl>

                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateUser}>
                                Update
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}