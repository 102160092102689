import { Flex } from "@chakra-ui/react"
import { ManageProduct } from "../components/product/manage-product"
import { Sidebar } from "../components/sidebar/sidebar"

export const ProductsPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageProduct/>
        </Flex>
    )
}