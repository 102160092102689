import { Box, Button, Flex, FormControl, FormLabel, Heading, HStack, Img, Input, Select, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect } from "react"
import { useRef } from "react"


import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import { Global } from "../../utils/global"
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';


export const UpdateBlog = () => {
    const editorRef = useRef();

    const styles = {
        editor: {
            border: '1px solid gray',
            minHeight: '6em'
        }
    };
    const [editorState, setEditorState] = useState(null);

    const [img, setImg] = useState("assets/images/eject.png");
    const [token, setToken] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [articleID, setArticleID] = useState(0)
    const [userID, setUserID] = useState(0)
    // const [article, setArticle] = useState({ articleID: 0 })
    const [usertype, setUserType] = useState("")
    const inputFile = useRef(null)
    const [values, setValues] = useState({
        title: "",
        content: "",
        section: "",
        entryText: "",
        category: "",
        entryText: "",
        tier: ""
    })

    const navigate = useNavigate()
    const { state } = useLocation()

    const onChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const UpdateArticle = () => {
        let html = stateToHTML(editorState.getCurrentContent());
        if (html != "") {
            values.content = html
        }
        //authenticate here
        if ((values.title == "") || (values.entryText == "") || (values.tier == "") || (values.userID == 0) || (values.content == "") || (values.section == "") || (values.category == "") || ((selectedFile == null) && (img == ""))) {
            Swal.fire("Blog",
                "Required details missing.",
                "error")
            return;
        }
        if (values.title.length > 80) {
            Swal.fire("Blog",
                "Title too long. Should be less than 80 characters.",
                "error")
            return;
        }

        var categories = [];
        categories.push(values.category)
        var params = { title: values.title, entryText: values.entryText, tier: values.tier, content: values.content, authorID: localStorage.getItem("userID"), categories: categories, section: values.section }
        console.log(params)
        axios
            .patch(Global.url + "/article/" + articleID, params, { headers: { token: token } })
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    addPhoto(articleID)
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    }

    const getArticle = async (id) => {
        axios
            .get(Global.url + "/article/" + id + "/get")
            .catch(err => {
                // what now?
                console.log(err);
            }).then(res => {
                console.log(res);
                if (res.data.success == 1) {
                    var message = res.data.message
                    document.getElementById("title").value = message.title
                    document.getElementById("section").value = message.section
                    values.section = message.section
                    document.getElementById("tier").value = message.tier
                    values.tier = message.tier
                    document.getElementById("entryText").value = message.entryText
                    values.entryText = message.entryText

                    // document.getElementById("content").value = message.content
                    document.getElementById("category").value = message.categories[0]
                    values.category = message.categories[0]
                    setImg(Global.awsS3url + "/images/" + message.photo)
                    values.title = message.title
                    const blocksFromHTML = convertFromHTML(message.content);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );

                    setEditorState(EditorState.createWithContent(state));
                } else {
                    Swal.fire("Error", res.data.message, "error");
                }
            });
    };

    function addPhoto(itemID) {

        if ((selectedFile == null) && (img != "")) {
            navigate("/articles")
            return
        }
        if (selectedFile == null) {
            Swal.fire("File missing", "Profile photo missing", "error")
            return;
        }
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("itemID", "article-" + articleID);
        axios
            .post(Global.url + "/item/image/upload", formData, { headers: { "Content-Type": "multipart/form-data", token: token } })
            .catch(err => {
                console.log(err)
            }).then(res => {
                if (res.data.success == 1) {
                    Swal.fire("Article Update", "Article successfully updated.", "success").then(() => {
                        navigate("/articles")
                    });
                } else {
                    Swal.fire("Photo Update", res.data.message, "error");
                }
            });
    }
    const openFile = () => {
        inputFile.current.click();
    }
    const onImageChange = (e) => {
        const [file] = e.target.files;
        setSelectedFile(e.target.files[0])
        setImg(URL.createObjectURL(file));
    };
    useEffect(() => {
        setEditorState(EditorState.createEmpty())
        var userType = localStorage.getItem("userType");
        var tokenStr = localStorage.getItem("token");
        var userID = localStorage.getItem("userID");
        setToken(tokenStr)
        setUserType(userType)
        setUserID(userID)
        if (state != null) {
            if ("articleID" in state) {
                setArticleID(state.articleID)
                getArticle(state.articleID)
            }
        }
    }, [])

    return (
        <Box
            flex={1}
            h="full"
            mt="2.5vh"
            mr={4}
            ml={8}
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            borderWidth={1}
            p={8}

        >
            <Box w="full" pb={4} borderBottomColor="gray.100" borderBottomWidth={1}>
                <Text textAlign="start" fontSize={28} fontWeight="bold" >Update article</Text>
            </Box>
            <Box p={8} w="full">
                <Flex flexDir="column" >
                    <VStack spacing={6} align="start">
                        <FormControl>
                            <FormLabel>Title</FormLabel>
                            <Input name="title" id="title" onChange={onChange} type="text" placeholder="Article title" />
                        </FormControl>
                        <FormControl style={styles.editor} >
                            <FormLabel>Content</FormLabel>
                            <Editor
                                ref={editorRef}
                                id="content"
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                placeholder="Write something!"
                            />
                            {/* <Textarea name="content" rows="15" onChange={onChange} type="text" placeholder="Article content goes here." /> */}
                        </FormControl>
                        <FormControl>
                            <FormLabel>Section</FormLabel>
                            <Select id="section" name="section" onChange={onChange} placeholder="Article section" >
                                <option value="highlight">Highlight</option>
                                <option value="other">Other</option>
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Summary</FormLabel>
                            <Textarea id="entryText" name="entryText" onChange={onChange} type="text" placeholder="Article entryText" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Category</FormLabel>
                            <Select id="category" name="category" onChange={onChange} placeholder="Article category" >
                                <option value="event">Event</option>
                                <option value="news">News</option>
                                <option value="merchandise">Merchandise</option>
                                <option value="sports">Sports</option>
                                <option value="transfer">Transfer</option>
                                <option value="matchup">Matchup</option>
                                <option value="streaming">Streaming</option>
                                <option value="launch">Launch</option>
                                <option value="offer">Offer</option>
                                <option value="holiday">Holiday</option>
                                <option value="gamecard">Gamecard</option>
                                <option value="giftcard">Giftcard</option>
                                <option value="gaming">Gaming</option>
                                <option value="documentary">Documentary</option>
                                <option value="e-sport">e-Sports</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel>Tier</FormLabel>
                            <Select id="tier" name="tier" onChange={onChange} placeholder="Select Tier" >
                                <option value="free">Free</option>
                                <option value="paid">Paid</option>
                            </Select>
                        </FormControl>
                        <VStack flex={1} align="start" spacing={4}>
                            <Img src={img} onClick={openFile} alt="Click to Select Article Image" w="100px" h="100px" />
                            <Input type="file" ref={inputFile} onChange={onImageChange} visibility="hidden"></Input>
                        </VStack>
                        <Box mt={4} w="full" alignItems="center">
                            <Button w="200px" variant="outline" colorScheme="teal.500" onClick={UpdateArticle}>
                                Update Article
                            </Button>
                        </Box>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    )
}