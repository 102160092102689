import { Flex } from "@chakra-ui/react"
import { ManageSales } from "../components/sales/manage-sales"
import { Sidebar } from "../components/sidebar/sidebar"

export const SalesPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageSales/>
        </Flex>
    )
}
