import { Flex } from "@chakra-ui/react"
import { ManageEvents } from "../components/event/manage-events"
import { Sidebar } from "../components/sidebar/sidebar"

export const EventPage = () => {
    return (
        <Flex>
            <Sidebar />
            <ManageEvents/>
        </Flex>
    )
}
